import React, { Component } from 'react';
import ReactDOM from 'react-dom/client';
import WebsiteApi from "../WebsiteApi";
import ReservationCalendar from "../components/ReservationCalendar";

class ArticleComponent extends Component {
    _getApi() {
        return new WebsiteApi();
    }

    componentDidUpdate(prevProps, prevState) {
        this.handleEmbeds();
    }

    componentDidMount() {
        if (!window?._twitterEmbedCache) {
            window._twitterEmbedCache = {};
        }
        this.handleEmbeds();
        ArticleComponent.handleDynamicComponents();
    }

    shouldComponentUpdate(nextProps) {
        return this.props.article.id !== nextProps.article.id;
    }

    static handleDynamicComponents() {
        if (window.updateSliders) {
            // Update image sliders
            window.updateSliders(true);
        }

        const available_components = {
            'ReservationCalendar': ReservationCalendar,
        }

        const component_placeholders = document.querySelectorAll('div[data-component*="DC:"]');
        const filtered_component_placeholders = Array.from(component_placeholders).filter(element => {
            return element.closest('.ck-editor') === null;
        });
        for (const component_placeholder of filtered_component_placeholders) {
            const component = component_placeholder.getAttribute('data-component').replace('DC:', '');
            if (!available_components[component]) {
                console.error(`Component not found: ${component}`);
                continue;
            }
            const element = React.createElement(available_components[component], {rendering: true});
            component_placeholder.removeAttribute('data-component');
            const dynamic_component_root = ReactDOM.createRoot(component_placeholder);
            dynamic_component_root.render(element);
            //
            /*const dynamic_article = article.content.replace(component_placeholder.outerHTML, component_placeholder.outerHTML.replace(component_placeholder.innerHTML, `<${component} rendering={true} />`));
            this.setState({
                article: dynamic_article,
            })*/
        }
    }

    handleEmbeds() {
        document.querySelectorAll( 'oembed[url]' ).forEach( element => {
            const url = element?.attributes?.url?.value;
            if (!url) {
                return;
            }
            if (url.includes('www.youtube.com/watch?v=') || url.includes('://youtu.be/') || url.includes('https://www.youtube.com/embed/')) {
                let embed_url = url.replace('https://youtu.be/', 'https://www.youtube.com/embed/');
                embed_url = embed_url.replace('https://www.youtube.com/watch?v=', 'https://www.youtube.com/embed/');
                embed_url = embed_url + '?feature=oembed&rel=0';
                const html = `<iframe width="100%" height="295" src="${embed_url}" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen="" class="Youtube-Embed"></iframe>`;
                element.innerHTML = '' + html;
                element.setAttribute('data-original-url', url);
                element.attributes.url.value = '';
                return;
            }
            if (url.includes('://www.twitter.com') || url.includes('://twitter.com')) {
                // Handle Twitter embeds
                const onEmbedHtmlReceived = (html) => {
                    element.innerHTML = '' + html;
                    element.setAttribute('data-original-url', url);
                    element.attributes.url.value = '';
                    this._injectTwitterEmbedScript();
                };
                if (window._twitterEmbedCache[url]) {
                    onEmbedHtmlReceived(window._twitterEmbedCache[url]);
                    return;
                }
                if (window._twitterEmbedCache[url] === false) {
                    return;
                }
                this._getApi().getResourceEmbed(url, (e) => {
                    window._twitterEmbedCache[url] = false;
                    console.error(`FAILED TO GET EMBED URL FOR ${url}`);
                }).then((html) => {
                    if (html) {
                        window._twitterEmbedCache[url] = html;
                        onEmbedHtmlReceived(window._twitterEmbedCache[url]);
                    }
                });
            }
            if (url.includes('://www.reddit.com') || url.includes('://reddit.com')) {
                // Handle Reddit embeds
                /**
                 * STORY: e.g. https://www.reddit.com/r/valheim/comments/m5mfaw/valheim_ceo_confirms_no_ore_teleporting/
                 * <blockquote class="reddit-embed-bq" style="height:500px" data-embed-height="546"><a href="https://www.reddit.com/r/Games/comments/1ca8233/valheim_ashlands_gameplay_trailer/">Valheim: Ashlands Gameplay Trailer</a><br> by<a href="https://www.reddit.com/user/Miss__Solstice/">u/Miss__Solstice</a> in<a href="https://www.reddit.com/r/Games/">Games</a></blockquote>
                 * COMMENT: e.g. https://www.reddit.com/r/Games/comments/1ca8233/comment/l0q8gka/?utm_source=share&utm_medium=web3x&utm_name=web3xcss&utm_term=1&utm_content=share_button
                 * <blockquote class="reddit-embed-bq" data-embed-showtitle="true" data-embed-height="288"><a href="https://www.reddit.com/r/valheim/comments/m5mfaw/comment/gr0v9h3/">Comment</a><br> by<a href="https://www.reddit.com/user/Flooo134/">u/Flooo134</a> from discussion<a href="https://www.reddit.com/r/valheim/comments/m5mfaw/valheim_ceo_confirms_no_ore_teleporting/"><no value=""></no></a><br> in<a href="https://www.reddit.com/r/valheim/">valheim</a></blockquote>
                 *
                 * SCRIPT: <script async="" src="https://embed.reddit.com/widgets.js" charset="UTF-8"></script>
                 */
                const onEmbedHtmlReceived = (html) => {
                    element.innerHTML = '' + html;
                    element.setAttribute('data-original-url', url);
                    element.attributes.url.value = '';
                    this._injectRedditEmbedScript();
                };
                const clean_url = url.split('?')[0];
                const html = '<blockquote class="reddit-embed-bq"><a href="' + clean_url + '">Title</a><br></blockquote>';
                onEmbedHtmlReceived(html);
            }
        } );
    }

    _injectTwitterEmbedScript() {
        const scriptURL = 'https://platform.twitter.com/widgets.js';
        const existing_script_element = document.querySelector(`script[src*="${scriptURL}"]`);
        if (existing_script_element) {
            // Remove previous script inclusion to re-init after DOM updates to re-render elements
            existing_script_element.remove();
        }

        const script = document.createElement('script');
        script.async = true;
        script.src = scriptURL;
        script.charset = 'utf-8';
        document.head.appendChild(script);
        /*script.onload = function() {
            console.log('Twitter widgets script loaded successfully.');
        };
        script.onerror = function() {
            console.error('Error loading the Twitter widgets script.');
        };*/
    }

    _injectRedditEmbedScript() {
        const scriptURL = 'https://embed.reddit.com/widgets.js';
        const existing_script_element = document.querySelector(`script[src*="${scriptURL}"]`);
        if (existing_script_element) {
            // Remove previous script inclusion to re-init after DOM updates to re-render elements
            existing_script_element.remove();
        }

        const script = document.createElement('script');
        script.async = true;
        script.src = scriptURL;
        script.charset = 'utf-8';
        document.head.appendChild(script);
    }

    render() {
        const {article} = this.props;
        return (
            <article dangerouslySetInnerHTML={{ __html: article.content }} />
        );
    }
}

export default ArticleComponent;
