import './PageCommon.css';
import React from 'react';
import {useParams} from "react-router";
import {useNavigate} from "react-router-dom";
import {AppContext} from "../App";
import {NewsPage} from "./NewsPage";

class TournamentPage extends NewsPage {
    resource_type = 'tournament';

    constructor(props) {
        super(props);
        this.resource_type = 'tournament';
        this.componentDidUpdate({}, {}, {});
    }

    getResourceTypeDisplayName(form) {
        const forms = ['Turnaus-sivu', 'Turnaus-sivua', 'Turnaus: ' + this.props.uri];
        return forms[form];
    }
}

const TournamentPageWithContext = (props) => {
    const { uri } = useParams();
    const navigate = useNavigate();
    return (<AppContext.Consumer>
        {(context) => {
            return <TournamentPage {...props} navigate={navigate} context={context} uri={uri} />
        }}
    </AppContext.Consumer>)
};
export default TournamentPageWithContext;
