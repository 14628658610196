import React from 'react';
import Header from "./navigation/Header";
import { Outlet } from "react-router-dom";
import Footer from "./navigation/Footer";

class Page extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="App">
                <Header />

                <main className="Main-canvas">
                    <Outlet />
                </main>

                <Footer />
            </div>
        );
    }
}

export default Page;
