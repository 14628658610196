import './PageCommon.css';
import React from 'react';
import {useParams} from "react-router";
import {useNavigate} from "react-router-dom";
import {AppContext} from "../App";
import {NewsPage} from "./NewsPage";

class TagPage extends NewsPage {
    constructor(props) {
        super(props);
        this.resource_type = 'tag:' + this.props.uri;
        this.componentDidUpdate({}, {}, {});
    }

    getResourceTypeDisplayName(form) {
        const forms = ['Aihe-sivu', 'Aihe-sivua', '#' + this.props.uri];
        return forms[form];
    }
}

const TagPageWithContext = (props) => {
    const { uri } = useParams();
    const navigate = useNavigate();
    return (<AppContext.Consumer>
        {(context) => {
            return <TagPage {...props} navigate={navigate} context={context} uri={uri} />
        }}
    </AppContext.Consumer>)
};
export default TagPageWithContext;
