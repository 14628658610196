import './App.css';
import React from 'react';
import {createBrowserRouter, RouterProvider} from "react-router-dom";

import WebsiteApi from "./WebsiteApi";
import Page from "./Page";
import Loading from "./Loading";
import HomePage from "./pages/HomePage";
import CommunityIndexPage from "./pages/CommunityIndexPage";
import TournamentIndexPage from "./pages/TournamentIndexPage";
import GamesPage from "./pages/GamesPage";
import PlayPage from "./pages/PlayPage";
import ErrorPage from "./pages/ErrorPage";
import ToSPage from "./pages/ToSPage";
import NewsPage from "./pages/NewsPage";
import GuidesPage from "./pages/GuidesPage";
import UserModal from "./modals/UserModal";
import ImageUploadModal from "./modals/ImageUploadModal";
import ArticlePage from "./pages/ArticlePage";
import ViewUserModal from "./modals/ViewUserModal";
import PelShop from "./pages/PelShop";
import TagPage from "./pages/TagPage";
import SidebarPreviewPage from "./pages/SidebarPreviewPage";
import TournamentPage from "./pages/TournamentPage";
import TournamentMatchPage from "./pages/TournamentMatchPage";
import TournamentTeamPage from "./pages/TournamentTeamPage";

const AppContext = React.createContext({});
export { AppContext };

const websiteBaseUrl = process.env.REACT_APP_BASE_URL || '/';

class App extends React.Component {
    registerUpdateListener() {
        if (this.update_timer) {
            clearTimeout(this.update_timer);
        }
        this.update_timer = setInterval(() => {
            this.receiveUpdates();
        }, this.update_timer_delay);
    }

    componentDidMount() {
        this.registerUpdateListener();
        window.document.addEventListener('mouseover', this.onMouseOver.bind(this));
    }

    componentWillUnmount() {
        if (this.update_timer) {
            clearTimeout(this.update_timer);
        }

        window.document.removeEventListener('mouseover', this.onMouseOver.bind(this));
    }

    onMouseOver(e, delay_finished) {
        if (e?.target?.tagName !== 'IMG') {
            return;
        }
        if (!e.target.classList.contains('Peak-View')) {
            return;
        }
        let parentLink = e.target.closest('a');
        const full_size_img_url = parentLink?.href;
        if (!full_size_img_url) {
            return;
        }
        const thumbnailOnMouseOut = (e) => {
            if (e.target.previewTimer) {
                clearTimeout(e.target.previewTimer);
            }
        };
        e.target.addEventListener('mouseout', thumbnailOnMouseOut);
        if (e.target.previewTimer) {
            clearTimeout(e.target.previewTimer);
        }
        if (!delay_finished) {
            const preloadImage = new Image();
            preloadImage.src = full_size_img_url;
            e.target.previewTimer = setTimeout(() => {
                this.onMouseOver(e, true);
            }, 500);
            return;
        }
        // Mouse movement stopped over the item and lasted >=500ms -> show preview


        const previewWidth = 512;
        const imgRect = e.target.getBoundingClientRect();
        let leftPos = imgRect.left;
        let topPos = imgRect.top + window.scrollY;
        const screenWidth = window.innerWidth;
        const screenHeight = window.innerHeight;
        if (leftPos + previewWidth > screenWidth) {
            // Adjust to fit within the screen (i.e. prevent overflow on right side of the screen)
            leftPos = screenWidth - previewWidth - 20;
        }
        let previewDiv = document.createElement('div');
        previewDiv.style.position = 'absolute';
        previewDiv.style.minWidth = `${previewWidth}px`;
        previewDiv.style.width = `${previewWidth}px`;
        previewDiv.style.height = '200px';
        previewDiv.style.left = `${leftPos}px`;
        previewDiv.style.top = `${topPos}px`;
        previewDiv.style.border = '1px solid black';
        previewDiv.style.zIndex = "1000";
        previewDiv.style.overflow = 'hidden';
        previewDiv.style.background = "rgba(255, 255, 255, 0.98)";
        previewDiv.style.boxShadow = "2px 2px 5px #666";
        previewDiv.style.cursor = 'pointer';
        previewDiv.onclick = (e) => {
            if (window._peakViewOnClick) {
                window._peakViewOnClick(e);
                previewDiv.remove(); // Destroy preview on click
                return false;
            }
            window.open(full_size_img_url, '_blank');
        };
        previewDiv.addEventListener('contextmenu', (e) => {
            e.preventDefault();
            previewDiv.remove(); // Destroy preview on right click
            return false;
        }, false);

        let onMouseOutListener;
        onMouseOutListener = () => {
            previewDiv.remove();
            window._activePeakView = null;
        };
        let largerImg = document.createElement('img');
        largerImg.src = full_size_img_url;
        if (parentLink.getAttribute('data-image-id')) {
            largerImg.setAttribute('data-image-id', parentLink.getAttribute('data-image-id'));
        }
        largerImg.style.width = '512px';
        largerImg.onload = () => {
            previewDiv.style.height = largerImg.clientHeight + 'px';
            if (topPos + largerImg.clientHeight + window.scrollY > screenHeight) {
                // Adjust to fit within the screen (i.e. prevent overflow on right side of the screen)
                topPos = screenHeight - largerImg.clientHeight - 20;
                previewDiv.style.top = `${topPos}px`;
            }
            largerImg.addEventListener('mouseout', onMouseOutListener);
            previewDiv.removeEventListener('mouseout', onMouseOutListener);
        };
        largerImg.onerror = () => {
            previewDiv.style.height = '20px';
            previewDiv.style.width = '20px';
        };

        previewDiv.appendChild(largerImg);
        document.body.appendChild(previewDiv);
        if (window?._activePeakView) {
            // Only one preview visible at a time
            window._activePeakView.remove();
        }
        window._activePeakView = previewDiv;
        previewDiv.addEventListener('mouseout', onMouseOutListener);
    }

    acquireSession = () => {
        return this.api.acquireSession().then((data) => {
            this.setState({
                visitor: data,
                is_initialized: true
            });
        });
    };

    updateSession = (user_data) => {
        this.setState({
            visitor: user_data,
        });
    };

    updateImageSliders() {
        window.setupImageSliderUpdateTimer = () => {
            if (window.updateSliders_timeout) {
                clearInterval(window.updateSliders_timeout);
            }
            window.updateSliders_timeout = setInterval(window.updateSliders, 15000);
        };
        window.updateSliders = (initial_run) => {
            if (window._contentEditor_active) {
                return; // Ignore when editor is open
            }
            const main = document.querySelector('main');
            const sliders = main.querySelectorAll('div.Dynamic-Image-Slider');
            const filtered_sliders = Array.from(sliders).filter(element => {
                return element.closest('.ck-editor') === null;
            });
            for (const slider of filtered_sliders) {
                const slider_first_image = slider.querySelector('li');
                let slider_image_active = slider.querySelector('li.Current-Slider-Image');
                let next_image;
                if (!slider_image_active) {
                    next_image = slider_first_image;
                } else {
                    if (initial_run) {
                        return;
                    }
                    next_image = slider_image_active.nextSibling;
                    if (!next_image) {
                        next_image = slider_first_image;
                    }
                    slider_image_active.style.display = 'none';
                    slider_image_active.className = '';
                    slider_image_active.onclick = null;
                }
                next_image.style.display = 'block';
                next_image.className = 'Current-Slider-Image';
                next_image.onclick = (e) => {
                    e.preventDefault();
                    window.setupImageSliderUpdateTimer();
                    window.updateSliders();
                };
                next_image.style.cursor = 'pointer';
            }
        };
        window.setupImageSliderUpdateTimer();
    }

    receiveUpdates = () => {
        return this.api.receiveUpdates().then((data) => {
            if (!data || !data.components) {
                return;
            }
            let new_state = {...this.state};
            for (const property_key of Object.keys(data)) {
                if (property_key === 'next_query_in') {
                    const old_update_timer_delay = this.update_timer_delay;
                    this.update_timer_delay = parseInt(data[property_key], 10);
                    if (this.update_timer_delay < 15000) {
                        this.update_timer_delay = 15000;
                    }
                    if (old_update_timer_delay !== this.update_timer_delay) {
                        this.registerUpdateListener(); // Re-register update timer
                    }
                    continue;
                }
                new_state[property_key] = data[property_key];
            }
            this.setState(new_state);
        });
    };

    constructor(props) {
        super(props);
        this.update_timer = null;
        this.update_timer_delay = 60000;
        this.state = {
            is_initialized: false,
            visitor: null,
            components: {},
            home: {},
            updateContext: (key, value) => {
                this.state[key] = value;
                this.setState(this.state);
            },
        };
        this.api = new WebsiteApi();
        this.acquireSession();
        this.receiveUpdates();
        this.updateImageSliders();
        window._forceUpdate = () => { // @todo: use windowMessage or events?
            return this.receiveUpdates();
        };
        window._forceSessionUpdate = (user_data) => {
            this.updateSession(user_data);
        };
        window._forceSessionRefresh = () => {
            return this.acquireSession();
        };
        let _prevPageUri = null;
        window._allowEdit = false;
        window._onPageChange = (component, uri, allow_edit) => {
            let page_metadata = {};
            if (component?.getPageMetadata) {
                page_metadata = component.getPageMetadata();
            }
            window._setPageMeta(page_metadata);
            window._allowEdit = allow_edit;

            if (_prevPageUri !== uri) { // _prevPageUri !== null &&
                window._forceUpdate(); // Force to reload page related updates when page URI changes
                setTimeout(() => {
                    if (uri === '/') {
                        // Ignore scrolling on homepage
                        return;
                    }
                    window.scrollTo({
                        top: 0,
                        behavior: 'smooth'
                    });
                }, 200);
            }
            _prevPageUri = uri;
        };
        window._setPageMeta = (metadata) => {
            const updateOgProperty = (property, value) => {
                const ogMetaTag = document.querySelector('meta[property="og:' + property + '"]');
                if (!ogMetaTag) {
                    return;
                }
                if (ogMetaTag.getAttribute('content') === value) {
                    // Not changed
                    return;
                }
                ogMetaTag.setAttribute('content', value);
            };
            const updateCanonicalUrl = (value) => {
                const linkCanonicalTag = document.querySelector('link[rel="canonical"]');
                if (!linkCanonicalTag) {
                    return;
                }
                if (linkCanonicalTag.getAttribute('href') === value) {
                    // Not changed
                    return;
                }
                linkCanonicalTag.setAttribute('href', value);
            };
            const updateMetaByName = (name, value) => {
                const metaTag = document.querySelector('meta[name="' + name + '"]');
                if (!metaTag) {
                    return;
                }
                if (metaTag.getAttribute('content') === value) {
                    // Not changed
                    return;
                }
                metaTag.setAttribute('content', value);
            };

            const page_url = `https://${window.location.hostname}${window.location.pathname}`; // Set URL without query parameters
            updateOgProperty('url', page_url);
            updateCanonicalUrl(page_url);
            const page_title = 'Pelaajat.com | ' + (metadata?.title ? metadata.title : 'Suomen suurin e-urheilumedia');
            const page_desc = metadata?.ogDesc ? metadata?.ogDesc : 'Pelaajat.com tarjoaa sinulle hermeettiset pelilähetykset ja tuoreimmat uutiset e-urheilusta';
            if (document.title !== page_title) {
                // Page title has changed
                document.title = page_title;
            }
            updateOgProperty('title', page_title);
            const img_url = (metadata?.img ? metadata.img : websiteBaseUrl + 'pelaajat-esports.jpg');
            const thumb_img_url = (metadata?.thumb ? metadata.thumb : websiteBaseUrl + 'pelaajat-esports.jpg');
            if (img_url && metadata?.imgWidth) {
                updateOgProperty('image:width', metadata.imgWidth);
                // @todo: could set image:height as well
            }
            updateOgProperty('image', img_url);
            updateOgProperty('type', metadata?.ogType ? metadata?.ogType : 'article');
            updateOgProperty('description', page_desc);
            updateMetaByName('description', page_desc);
            const keyword_base = ["esports", "esportsfi", "eurheilu", "pelaaja", "pelaaminen", "pelit"];
            let categories = [];
            for (let category of (metadata?.categories || [])) {
                categories.push(category.title);
            }
            categories.push(...keyword_base);
            const keywords = categories.join(', ');
            const updateLdJson = () => {
                let ld_json = {
                    "@context": "http://schema.org",
                    "@type": metadata?.ldType ? metadata?.ldType : "NewsArticle",
                    "mainEntityOfPage":
                        {
                            "@type": "WebPage",
                            "@id": page_url
                        },
                    "headline": page_title,
                    "url": page_url,
                    "thumbnailUrl": thumb_img_url,
                    "image":{
                        "@type": "ImageObject",
                        "url": img_url
                    },
                    "articleSection": categories[0],
                    "author":[{"@type":"Person", "name": metadata?.authorName}],
                    "creator":[metadata?.authorName],
                    "publisher":{"@type":"Organization","name":"Pelaajat.com","logo": websiteBaseUrl + 'pelaajat-esports.jpg'},
                    "keywords": categories
                };
                if (metadata?.createdTs) {
                    const createdIsoStr = new Date(metadata.createdTs * 1000).toISOString();
                    ld_json['dateCreated'] = createdIsoStr;
                    ld_json['datePublished'] = createdIsoStr;
                    if (!metadata?.updatedTs) {
                        ld_json['dateModified'] = createdIsoStr;
                        updateMetaByName('article:modified_time', createdIsoStr);
                    }
                }
                if (metadata?.updatedTs) {
                    const updatedIsoStr = new Date(metadata.updatedTs * 1000).toISOString();
                    ld_json['dateModified'] = updatedIsoStr;
                    updateMetaByName('article:modified_time', updatedIsoStr);
                }
                const scriptElement = document.querySelector('script[type="application/ld+json"]');
                if (!scriptElement) {
                    return;
                }
                const ld_json_str = JSON.stringify(ld_json);
                if (scriptElement.innerHTML !== ld_json_str) {
                    // Page properties have changed, update LD JSON
                    scriptElement.innerHTML = ld_json_str;
                }
                updateMetaByName('keywords', keywords);
            };
            updateLdJson();
        };
    }

    render() {
        if (!this.state.is_initialized) {
            return (
                <Loading/>
            )
        }

        const router = createBrowserRouter([
            {
                path: "/",
                element: <Page />,
                errorElement: <ErrorPage />,
                children: [
                    {
                        index: true,
                        element: <HomePage />,
                    },
                    {
                        "path": "/",
                        element: <HomePage />,
                    },
                    {
                        // Special page: combines all types that are trending
                        "path": "/yhteisö",
                        element: <CommunityIndexPage />,
                    },
                    {
                        "path": "/pelaa",
                        element: <PlayPage index={true} />,
                    },
                    {
                        "path": "/kauppa",
                        element: <PelShop index={true} />,
                    },
                    {
                        "path": "/kauppa/:uri",
                        element: <PelShop />,
                    },
                    {
                        "path": "/aihe/:uri",
                        element: <TagPage index={true} />,
                    },
                    {
                        "path": "/kategoria/:uri",
                        element: <TagPage index={true} />,
                    },
                    {
                        "path": "/pelit",
                        element: <GamesPage index={true} />,
                    },
                    {
                        "path": "/pelit/:uri",
                        element: <GamesPage />,
                    },
                    {
                        "path": "/oppaat/:uri",
                        element: <GuidesPage />,
                    },
                    {
                        "path": "/oppaat",
                        element: <GuidesPage index={true} />,
                    },
                    {
                        "path": "/turnaukset",
                        element: <TournamentIndexPage msg="Turnaukset placeholder" />,
                    },
                    {
                        "path": "/turnaus/:uri",
                        element: <TournamentPage index={false} />,
                    },
                    {
                        "path": "/ottelu/:uri",
                        element: <TournamentMatchPage index={false} />,
                    },
                    {
                        "path": "/joukkue/:uri",
                        element: <TournamentTeamPage index={false} />,
                    },
                    {
                        "path": "/yhteisöedut",
                        element: <ArticlePage uri="/yhteisöedut" index={false} />,
                    },
                    {
                        "path": "/bootcamp",
                        element: <ArticlePage uri="/bootcamp" index={false} />,
                    },
                    {
                        //<Route path="/modal/:id" element={<Modal />} />
                        "path": "/pelaaja",
                        element: <UserModal />,
                    },
                    {
                        "path": "/pelaaja/avatar",
                        element: <ImageUploadModal purpose="avatar" />,
                    },
                    {
                        "path": "/pelaaja/salasana",
                        element: <UserModal view="password" />,
                    },
                    {
                        "path": "/pelaaja/email",
                        element: <UserModal view="email" />,
                    },
                    {
                        "path": "/pelaaja/tiedot",
                        element: <UserModal view="info" />,
                    },
                    {
                        "path": "/pelaaja/käyttäjänimi",
                        element: <UserModal view="username" />,
                    },
                    {
                        "path": "/pelaaja/twitch",
                        element: <UserModal view="twitch" />,
                    },
                    {
                        "path": "/pelaaja/discord",
                        element: <UserModal view="discord" />,
                    },
                    {
                        "path": "/pelaaja/heippa",
                        element: <UserModal view="logged-out" />,
                    },
                    {
                        "path": "/pelaaja/kirjaudu",
                        element: <UserModal view="login-form" />,
                    },
                    {
                        "path": "/pelaaja/:id",
                        element: <ViewUserModal />,
                    },
                    {
                        "path": "/uutiset",
                        element: <NewsPage index={true} />,
                        // errorElement: <ErrorPage />, // @todo: ADD ONE
                    },
                    {
                        "path": "/uutiset/:uri",
                        element: <NewsPage />,
                        // errorElement: <ErrorPage />, // @todo: ADD ONE
                    },
                    {
                        "path": "/sidebar/:uri",
                        element: <SidebarPreviewPage />,
                    },
                    {
                        "path": "/tos",
                        element: <ToSPage />,
                    },
                    {
                        "path": "/:uri",
                        element: <ArticlePage index={false} />,
                    },
                ],
            },
            /*{
                path: "/foo",
                element: <Page state={this.state} />,
                //errorElement: <ErrorPage />,
            },*/
        ], {"basename": websiteBaseUrl.replace(/\/$/, '')});

        return (
            <React.StrictMode>
                <AppContext.Provider value={this.state}>
                    <RouterProvider router={router} fallbackElement={<Loading/>} />
                </AppContext.Provider>
            </React.StrictMode>
        );
    }
}

export default App;
