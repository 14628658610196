export const LogoEmblem = () => {
    return(
        <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
             width="117.000000pt" height="183.000000pt" viewBox="0 0 117.000000 183.000000"
             preserveAspectRatio="xMidYMid meet">

            <g transform="translate(0.000000,183.000000) scale(0.100000,-0.100000)"
               fill="#ff3300" stroke="none">
                <path d="M210 1330 c0 -76 17 -125 47 -134 10 -2 102 -8 206 -11 215 -8 234
-15 275 -95 28 -54 24 -106 -13 -161 -73 -111 -265 -79 -291 49 l-6 32 -109 0
-109 0 0 -257 c1 -187 4 -262 13 -274 22 -29 55 -39 131 -39 l76 0 0 120 c0
114 1 120 19 111 10 -6 47 -15 83 -21 166 -26 326 57 400 208 30 61 33 74 33
162 0 82 -4 103 -27 152 -53 113 -158 195 -279 218 -29 6 -141 10 -250 10
l-199 0 0 -70z"/>
                <path d="M547 1062 c-36 -39 -7 -102 45 -102 25 0 58 36 58 63 0 50 -70 76
-103 39z"/>
            </g>
        </svg>
    );
};