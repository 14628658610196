import './NewsBox.css';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import {AppContext} from "../App";
import WebsiteApi from "../WebsiteApi";
import Loading from "../Loading";

class NewsBox extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            'news': this.props?.context?.components?.news || [],
            'isLoadingMore': false,
            'noMoreNews': false,
        };
    }

    _getApi() {
        return new WebsiteApi();
    }

    openArticle(article) {
        if (!article.link) {
            return;
        }
        this.props.navigate(article.link);
    }

    getNewsContent() {
        const news = this.state?.news;
        let news_elements = [];
        for (let article of news) {
            news_elements.push((
                <li>
                    <a href={article.link} onClick={(e) => {
                        e.preventDefault();
                        this.openArticle(article);
                    }}>
                        <img src={article.img} className="NewsBox-Article-Image" />
                        {article.topic}
                    </a>
                </li>
            ));
        }
        return (
            <div className="NewsBox-Article-List-Container" id="InfiniteNewsBoxScroll">
                <ul className="NewsBox-Article-List">
                    {news_elements}
                </ul>
            </div>
        );
    }

    onNewsBoxScroll(e) {
        if (this.state.isLoadingMore || this.state.noMoreNews) {
            // Ignore event while we are loading more news
            return;
        }
        const news_box = document.getElementById('InfiniteNewsBoxScroll');
        if (!news_box) {
            return;
        }
        let totalScrollWidth = news_box.scrollWidth - news_box.clientWidth;
        let currentScrollPosition = news_box.scrollLeft;
        if (currentScrollPosition >= 0.8 * totalScrollWidth) {
            this._loadMoreNews();
        }
    }

    _loadMoreNews() {
        this.setState({
            'isLoadingMore': true,
        });
        let ts = null;
        if (this.state?.news?.length > 0) {
            ts = this.state.news[this.state.news.length - 1].published_on_ts || null;
        }
        return this._getApi().getLatestNews(ts).then((data) => {
            if (data?.response?.status > 299) {
                // An error occurred
                console.error('Failed to retrieve latest news!');
                return data;
            }
            for (let article of data) {
                this.state.news.push(article);
                if (this.state.news.length > 50) {
                    this.state.news.shift(); // Remove the first item from the array to keep it sensible, i.e. don't show thousands of news items on the component if open for whole day
                }
            }
            if (this.state.news.length === 50) {
                const news_box = document.getElementById('InfiniteNewsBoxScroll');
                if (news_box) {
                    news_box.scrollLeft = news_box.scrollLeft - (news_box.scrollLeft * 0.1); // scroll back 10%
                }
            }
            const noMoreNews = (data.length < 10);
            this.setState({
                'news': this.state.news,
                'isLoadingMore': false,
                'noMoreNews': noMoreNews,
            });
            return data;
        });
    }

    componentDidMount() {
        const news_box = document.getElementById('InfiniteNewsBoxScroll');
        if (news_box) {
            news_box.addEventListener('scroll', this.onNewsBoxScroll.bind(this));
        }
    }

    componentWillUnmount() {
        const news_box = document.getElementById('InfiniteNewsBoxScroll');
        if (news_box) {
            news_box.removeEventListener('scroll', this.onNewsBoxScroll.bind(this));
        }
    }

    render() {
        const news = this.props?.context?.components?.news;
        if (!news || !(news.length > 1)) {
            return (
                <Loading inline={true} />
            );
        }
        return (
            <div className="Community-Box">
                <h2 className="Community-Box-Title">Uutiset</h2>
                {this.getNewsContent()}
            </div>
        );
    }
}

const NewsBoxWithContext = (props) => {
    let navigate = useNavigate();
    return (<AppContext.Consumer>
        {(context) => {
            return <NewsBox {...props} navigate={navigate} context={context} />
        }}
    </AppContext.Consumer>)
};

export default NewsBoxWithContext;

