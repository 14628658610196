import './HomePage.css';
import React from 'react';
import ContentColumn from "../layout/ContentColumn";
import SideBar from "../layout/SideBar";
import BroadcastBox from "../boxes/BroadcastBox";
import CommunityFeedBox from "../boxes/CommunityFeedBox";
import {Link, useNavigate} from "react-router-dom";
import {AppContext} from "../App";
import Loading from "../Loading";
import AdBox from "../boxes/AdBox";
import NewsBox from "../boxes/NewsBox";

class HomePage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            'active_slide': 0,
            'is_initialized': false,
        }
        this.initialize();
    }

    initialize() {
        if (this.state.is_initialized) {
            return;
        }
        this.setState({
            'is_initialized': true
        });
    }

    componentDidMount() {
        this._registerTouchControls();
    }

    componentWillUnmount() {
        this._registerTouchControls(true);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        window._onPageChange(this, '/');
    }

    getPageMetadata() {
        return {
            ogType: 'website',
            ldType: 'WebPage',
        };
    }

    _getStories() {
        return this.props.context?.home?.slider;
    }

    _getActiveSlideIndex() {
        return parseInt(this.state.active_slide, 10);
    }

    _getBlurForPosition(index) {
        const active_slide = this._getActiveSlideIndex();
        if (active_slide === index) {
            return 0;
        }
        return 5;
    }

    _getTransformPosition(index) {
        const stories = this._getStories();
        const active_slide = this._getActiveSlideIndex();

        if (index === 0 && active_slide === (stories.length - 1) && stories.length > 2) {
            // If there are more than 2 stories, "loopback" the first story to last
            return 750;
        }

        if (active_slide === index) {
            return 0;
        }
        if (active_slide < index) {
            return 750 * (index - active_slide);
        }
        if (active_slide > index) {
            return -750 * (active_slide - index);
        }
        console.error('FAILED TO CALCULATE TRANSFORM POSITION FOR A SLIDE!');
        return -2000; // Should not happen
    }

    getSliderElement(story, index) {
        return (
            <div className="Slider" style={{'backgroundImage': 'url(' + story.img + ')', 'transform': 'translate3d(' + this._getTransformPosition(index) + 'px, 0px, 0px)', 'filter': 'blur(' + this._getBlurForPosition(index) + 'px)'}} onClick={(e) => {
                e.preventDefault();
                this.props.navigate(story.link);
            }}>
                <Link className="SliderStory-link" to={story.link}>
                    {story.topic}
                </Link>
            </div>
        )
    }

    nextSlide() {
        const stories = this._getStories();
        const active_slide = this._getActiveSlideIndex();
        if (active_slide + 1 === stories.length) {
            this.setState({
                'active_slide': 0
            });
            return;
        }
        this.setState({
            'active_slide': active_slide + 1
        });
    }

    previousSlide() {
        const stories = this._getStories();
        const active_slide = this._getActiveSlideIndex();
        if (active_slide - 1 < 0) {
            this.setState({
                'active_slide': stories.length - 1
            });
            return;
        }
        this.setState({
            'active_slide': active_slide - 1
        });
    }

    _getStorySlider() {
        const stories = this._getStories();
        let story_slider = [];
        for (const i in stories) {
            const story = stories[i];
            story_slider.push(this.getSliderElement(story, parseInt(i, 10)));
        }
        return story_slider;
    }

    onTouchStart(e) {
        if (!e.target.closest('.Slider')) {
            // Ignore, touch happened outside of the slider
            return;
        }
        window.touchStartX = e.changedTouches[0].screenX;
    }

    onTouchEnd(e) {
        if (!e.target.closest('.Slider')) {
            // Ignore, touch happened outside of the slider
            return;
        }
        window.touchEndX = e.changedTouches[0].screenX;

        if (Math.abs(window.touchStartX - window.touchEndX) < 30) {
            // Too short swipe
            return;
        }
        if (window.touchEndX < window.touchStartX) {
            // (right-to-left; right swipe)
            this.nextSlide();
            return;
        }
        // (left-to-right; left swipe)
        this.previousSlide();
    }

    _registerTouchControls(unregister) {
        window.touchStartX = 0;
        window.touchEndX = 0;

        if (unregister) {
            document.removeEventListener('touchstart', this.onTouchStart.bind(this));
            document.removeEventListener('touchend', this.onTouchEnd.bind(this));
            return;
        }
        document.addEventListener('touchstart', this.onTouchStart.bind(this));
        document.addEventListener('touchend', this.onTouchEnd.bind(this));
    }

    getContentColumnContents() {
        const stories = this._getStories();

        if (!stories || !(stories.length > 0)) {
            return (
                <div>
                    <Loading />
                </div>
            );
        }

        const slider_content = (
            <div className="Slider-Wrapper">
                <a href="#" className="Next-Arrow" onClick={(e) => {
                    this.nextSlide();
                    e.preventDefault();
                }}></a>
                <a href="#" className="Previous-Arrow" onClick={(e) => {
                    this.previousSlide();
                    e.preventDefault();
                }}></a>
                {this._getStorySlider()}
            </div>
        );

        const is_live_broadcast = this.props?.context?.components?.broadcast?.is_live;

        let content = [];
        content.push((<BroadcastBox live={true}/>));
        if (is_live_broadcast) {
            content.push((<AdBox position="home" />));
        }
        content.push(slider_content);
        if (!is_live_broadcast) {
            content.push((<AdBox position="home" />));
        }
        content.push((<NewsBox />));
        content.push((<CommunityFeedBox />));
        content.push(<BroadcastBox live={false} />);

        return (
            <>
                {content}
            </>
        );
    }

    render() {
        return (
            <div>
                <ContentColumn content={this.getContentColumnContents()} />
                <SideBar />
            </div>
        );
    }
}

const HomePagewithContext = (props) => {
    const navigate = useNavigate();
    return (<AppContext.Consumer>
        {(context) => {
            return <HomePage {...props} navigate={navigate} context={context} />
        }}
    </AppContext.Consumer>);
};

export default HomePagewithContext;

