import './HomePage.css';
import React from 'react';
import ContentColumn from "../layout/ContentColumn";
import {AppContext} from "../App";

class ToSPage extends React.Component {
    getContentColumnContents() {
        return (
            <div>
                Käyttöehdot tulee tänne :-)
            </div>
        )
    }
    render() {
        return (
            <div>
                <ContentColumn content={this.getContentColumnContents()} />
            </div>
        )
    }
}

const withContext = (props) => {
    return (<AppContext.Consumer>
        {(context) => {
            return <ToSPage {...props} context={context} />
        }}
    </AppContext.Consumer>)
};

export default withContext;

