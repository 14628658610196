import React from 'react';

class NotFound extends React.Component {
    constructor(props) {
        super(props);
        this.state = props.state;
    }

    render() {
        return (
            <div>Tuotanto hoitaa..tai ei hoitanut tai saattoi hoitaa, mutta huonosti ja lopputulos on kuitenkin se, että sivua ei löytynyt.</div>
        );
    }
}

export default NotFound;
