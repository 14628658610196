import './UserModal.css';
import '../pages/PageCommon.css';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import {AppContext} from "../App";
import WebsiteApi from "../WebsiteApi";
import twitchLogo from "../images/twitch_login_btn.png";
import discordLogo from "../images/discord_login_btn.png";
import Cookies from "universal-cookie";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {icon} from "@fortawesome/fontawesome-svg-core/import.macro";
import UserTags from "../tools/UserTags";
import WarningNote from "../elements/WarningNote";

const websiteBaseUrl = process.env.REACT_APP_BASE_URL || '/';

export class UserModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            'reg_phase': null,
            'msg': null,
            'is_loading': false,
            'sso': null,
            'sso_discord': null,
            'profile_subtab': 'profile',
        };
    }

    _getApi() {
        return new WebsiteApi();
    }

    _getVisitor() {
        return this.props?.context?.visitor;
    }

    _getView() {
        return this.props?.view;
    }

    _getCurrentPhase() {
        return this.state.reg_phase || 0;
    }

    _login() {
        this.setState({
            'is_loading': true,
            'msg': null,
        });

        this._getApi().login({
            'username': this.refs.username.value,
            'password': this.refs.password.value,
        }).then((data) => {
            const account_id = data?.account?.id;
            if (account_id) {
                // Login was a success!
                window._forceSessionUpdate(data);
                this.setState({
                    'is_loading': false,
                    'msg': {
                        'txt': 'HERMEETTISTÄ! KIRJAUTUMINEN ONNISTUI!',
                        'className': 'Message-Successful'
                    },
                });
                this.props.navigate('/pelaaja');
                return data;
            }
            const error_msg = data?.response?.data?.message || 'Äh, jotain meni vikaa, yritä uudestaan!';
            this.setState({
                'is_loading': false,
                'msg': {
                    'txt': error_msg,
                    'className': 'Message-Failure'
                },
            });
        }).catch((error) => {
            const error_msg = error?.response?.data?.message || 'Äh, jotain meni vikaa, yritä uudestaan!';
            this.setState({
                'is_loading': false,
                'msg': {
                    'txt': error_msg,
                    'className': 'Message-Failure'
                },
            });
            console.dir(error);
        });
    }

    _nextPhase() {
        let current_phase = this._getCurrentPhase();
        switch (current_phase) {
            case 1:
                this.setState({
                    'is_loading': true,
                    'msg': null,
                });
                this._getApi().createAccount({
                    'email': this.refs.email.value,
                    'username': this.refs.username.value,
                    'password': this.refs.password.value,
                }).then((data) => {
                    const account_id = data?.account?.id;
                    if (account_id) {
                        // Registration was a success!
                        window._forceSessionUpdate(data);
                        this.setState({
                            'reg_phase': ++current_phase,
                            'is_loading': false,
                            'msg': {
                                'txt': 'HERMEETTISTÄ! KÄYTTÄJÄTILISI ON NYT TALLENNETTU!',
                                'className': 'Message-Successful'
                            },
                        });
                        return data;
                    }
                    const error_msg = data?.response?.data?.message || 'Äh, jotain meni vikaa, yritä uudestaan!';
                    this.setState({
                        'is_loading': false,
                        'msg': {
                            'txt': error_msg,
                            'className': 'Message-Failure'
                        },
                    });
                }).catch((error) => {
                    const error_msg = error?.response?.data?.message || 'Äh, jotain meni vikaa, yritä uudestaan!';
                    this.setState({
                        'is_loading': false,
                        'msg': {
                            'txt': error_msg,
                            'className': 'Message-Failure'
                        },
                    });
                    console.dir(error);
                });
                return;
        }

        this.setState({
            'reg_phase': ++current_phase,
        });
    }

    componentDidMount() {
        document.addEventListener("keydown", this._keyDownHandler.bind(this));
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", this._keyDownHandler.bind(this));
    }

    _keyDownHandler(event) {
        if (event.key !== "Enter") {
            return;
        }
        if (this._getView() === 'login-form') {
            this._login();
            return;
        }
    }

    getVisitorModalRequestInfoPhaseContents() {
        const visitor = this._getVisitor();
        return (
            <>
                <h2>Säilytä käyttäjätilisi</h2>

                <div className="Flex-Row">
                    <div className="Flex-Column Align-Right">
                        <label htmlFor="signup_username">Käyttäjänimi:</label>
                    </div>
                    <div className="Flex-Column">
                        <input type="text" id="signup_username" ref="username" defaultValue={visitor.anonymous_name}
                               disabled={this.state.is_loading}/>
                    </div>
                </div>

                <div className="Flex-Row">
                    <div className="Flex-Column Align-Right">
                        <label htmlFor="signup_email">Sähköposti:</label>
                    </div>
                    <div className="Flex-Column">
                        <input type="email" id="signup_email" ref="email" disabled={this.state.is_loading}/>
                    </div>
                </div>

                <div className="Flex-Row">
                    <div className="Flex-Column Align-Right">
                        <label htmlFor="signup_password">Salasana:</label>
                    </div>
                    <div className="Flex-Column">
                        <input type="password" id="signup_password" ref="password" disabled={this.state.is_loading}/>
                    </div>
                </div>

                <div className="Flex-Row">
                    <div className="Flex-Column Align-Right">
                        &nbsp;
                    </div>
                    <div className="Flex-Column">
                        <button className="In-Place-Button" onClick={() => {
                            this._nextPhase();
                        }} disabled={this.state.is_loading}>
                            Luo käyttäjätili
                        </button><br />
                        {this._getMessageToDisplay()}
                    </div>
                </div>

            </>
        )
    }

    showLoginForm(e) {
        e.preventDefault();
        this.props.navigate('/pelaaja/kirjaudu');
    }

    getVisitorModalInitialPhaseContents() {
        const visitor = this._getVisitor();

        const unknown_player_img = websiteBaseUrl + 'pelcom_up.jpg';
        return (
            <div className="User-Profile-Container">
                <div className="Left-Column Register-Mock-Profile">
                    <h2>{visitor.anonymous_name}</h2>
                    <hr />
                    <img src={unknown_player_img} />
                </div>
                <div className="Middle-Column Register-Profile-Benefits">
                    <h2>Tilapäinen käyttäjätilisi</h2>
                    <p>
                        <strong>
                            Tämä on tilapäinen käyttäjätilisi {visitor.anonymous_name} (#{visitor.anonymous_name_id}).
                        </strong>
                    </p>
                    <p>
                        Kun vaihdat laitetta tai selaimesi evästeet poistetaan, menetät hermeettisen käyttäjätilisi
                        kokonaan - dodgea XP:n menetys ja rekisteröi käyttäjätilisi säilyttääksesi sen aina.
                    </p>
                    <h3>Rekisteröityneenä käyttäjänä saat myös:</h3>
                    <ul>
                        <li>Muokata käyttäjänimeäsi</li>
                        <li>Peukuttamisen lisäksi alapeukuttaa sisältöjä</li>
                        <li>Lisätä linkkejä kommentteihin</li>
                        <li>Lisätä liitteitä kommentteihin</li>
                    </ul>
                </div>
                <div className="Right-Column Register-Actions-Column">
                    <button className="Full-Width-Button Login-Button" onClick={(e) => {
                        this.showLoginForm(e);
                    }}>Kirjaudu</button>

                    <p>Rekisteröi tilisi käyttäen</p>
                    <ul>
                        <li>
                            <a href="" onClick={(e) => {
                                this.onTwitchLoginClick(e);
                            }}>
                                <img src={twitchLogo} alt="Twitch" />
                            </a>
                        </li>
                        <li>
                            <a href="" onClick={(e) => {
                                this.onDiscordLoginClick(e);
                            }}>
                                <img src={discordLogo} alt="Discord" />
                            </a>
                        </li>
                        <li>
                            <button className="In-Place-Button Register-Button" onClick={() => {
                                this._nextPhase();
                            }}>Sähköpostia
                            </button>
                        </li>
                    </ul>
                </div>
            </div>
        )
    }

    getLoginFormContents() {
        return (
            <div className="User-Profile-Container">
                <div className="Left-Column"></div>
                <div className="Middle-Column">
                    <h2>Kirjaudu sisään</h2>
                    <div>
                        <label>
                            Sähköposti/käyttäjänimi:<br/>
                            <input type="username" ref="username"
                                   disabled={this.state.is_loading}/>
                        </label>
                    </div>
                    <div>
                        <label>
                            Salasana:<br/>
                            <input type="password" ref="password" disabled={this.state.is_loading}/>
                        </label>
                    </div>

                    <div>
                        {this._getMessageToDisplay()}
                    </div>
                    <button className="In-Place-Button" onClick={() => {
                            this._login();
                        }} disabled={this.state.is_loading} style={{'minWidth': '170px'}}>
                        Kirjaudu
                    </button>
                </div>
                <div className="Right-Column">
                    <p>Kirjaudu käyttäen</p>
                    <ul>
                        <li>
                            <a href="" onClick={(e) => {
                                this.onTwitchLoginClick(e);
                            }}>
                                <img src={twitchLogo} alt="Twitch" />
                            </a>
                        </li>
                        <li>
                            <a href="" onClick={(e) => {
                                this.onDiscordLoginClick(e);
                            }}>
                                <img src={discordLogo} alt="Discord" />
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        );
    }

    getVisitorModalContents() {
        const current_phase = this._getCurrentPhase();
        switch (current_phase) {
            case 1:
                return this.getVisitorModalRequestInfoPhaseContents();
            case 0:
            default:
                return [this.getVisitorModalInitialPhaseContents()];
        }
    }

    _getMessageToDisplay() {
        let msg = null;
        if (this.state?.msg?.txt) {
            msg = (
                <div className={this.state.msg.className}>
                    {this.state.msg.txt}
                </div>
            )
        }
        return msg;
    }

    _onClickClearStateStopDefaultAction(event) {
        event.preventDefault();
        this.setState({
            'msg': null,
        });
    }

    onProfileImageClick(event) {
        this._onClickClearStateStopDefaultAction(event);
        this.props.navigate('/pelaaja/avatar');
        return false;
    }

    onGoBackClick(event) {
        this._onClickClearStateStopDefaultAction(event);
        this.props.navigate('/pelaaja');
        return false;
    }

    onPasswordChangeClick(event) {
        this._onClickClearStateStopDefaultAction(event);
        this.props.navigate('/pelaaja/salasana');
        return false;
    }

    onEmailChangeClick(event) {
        this._onClickClearStateStopDefaultAction(event);
        this.props.navigate('/pelaaja/email');
        return false;
    }

    onAccountInfoChangeClick(event) {
        this._onClickClearStateStopDefaultAction(event);
        this.props.navigate('/pelaaja/tiedot');
        return false;
    }

    onUsernameChangeClick(event) {
        this._onClickClearStateStopDefaultAction(event);
        this.props.navigate('/pelaaja/käyttäjänimi');
        return false;
    }

    onLogoutClick(event) {
        this._onClickClearStateStopDefaultAction(event);
        this.props.navigate('/pelaaja/heippa');
        const cookies = new Cookies();
        if (cookies.get('PL_SESSION')) {
            console.log('Logging out..');
            cookies.remove('PL_SESSION', {path: '/'});
            cookies.remove('PL_SESSION_CSRF', {path: '/'});
            console.log('Logged out!');
            window.location.reload();
        }
        return false;
    }

    onTwitchLoginClick(event) {
        event.preventDefault();

        this._getApi().getSsoLoginUrl('TWITCH').then((data) => {
            const login_url = data?.login_url;
            if (login_url) {
                window.location.href = login_url;
                return data;
            }
            const error_msg = data?.response?.data?.message || 'Äh, jotain meni vikaa, yritä uudestaan!';
            this.setState({
                'msg': {
                    'txt': error_msg,
                    'className': 'Message-Failure'
                },
            });
        }).catch((error) => {
            const error_msg = error?.response?.data?.message || 'Äh, jotain meni vikaa, yritä uudestaan!';
            this.setState({
                'msg': {
                    'txt': error_msg,
                    'className': 'Message-Failure'
                },
            });
            console.dir(error);
        });
        return false;
    }

    onDiscordLoginClick(event) {
        event.preventDefault();

        this._getApi().getSsoLoginUrl('DISCORD').then((data) => {
            const login_url = data?.login_url;
            if (login_url) {
                window.location.href = login_url;
                return data;
            }
            const error_msg = data?.response?.data?.message || 'Äh, jotain meni vikaa, yritä uudestaan!';
            this.setState({
                'msg': {
                    'txt': error_msg,
                    'className': 'Message-Failure'
                },
            });
        }).catch((error) => {
            const error_msg = error?.response?.data?.message || 'Äh, jotain meni vikaa, yritä uudestaan!';
            this.setState({
                'msg': {
                    'txt': error_msg,
                    'className': 'Message-Failure'
                },
            });
            console.dir(error);
        });
        return false;
    }

    onEmailChangeFormSubmit(event) {
        event.preventDefault();

        if (!this.refs.new_email.value) {
            // No new email provided?!
            this.setState({
                'msg': {
                    'txt': 'Anna uusi sähköpostiosoite!',
                    'className': 'Message-Failure'
                },
            });
            return false;
        }

        this.setState({
            'is_loading': true,
            'msg': null,
        });

        this._getApi().changeEmail({
            'new_email': this.refs.new_email.value,
        }).then((data) => {
            const account_id = data?.account?.id;
            if (account_id) {
                // Change was a success
                window._forceSessionUpdate(data);
                this.setState({
                    'is_loading': false,
                    'msg': {
                        'txt': 'HERMEETTISTÄ! SÄHKÖPOSTI VAIHDETTIIN!',
                        'className': 'Message-Successful'
                    },
                });
                this.props.navigate('/pelaaja');
                return data;
            }
            const error_msg = data?.response?.data?.message || 'Äh, jotain meni vikaa, yritä uudestaan!';
            this.setState({
                'is_loading': false,
                'msg': {
                    'txt': error_msg,
                    'className': 'Message-Failure'
                },
            });
        }).catch((error) => {
            const error_msg = error?.response?.data?.message || 'Äh, jotain meni vikaa, yritä uudestaan!';
            this.setState({
                'is_loading': false,
                'msg': {
                    'txt': error_msg,
                    'className': 'Message-Failure'
                },
            });
            console.dir(error);
        });
        return false;
    }

    onUsernameChangeFormSubmit(event) {
        event.preventDefault();

        if (!this.refs.new_username.value) {
            // No new username provided?!
            this.setState({
                'msg': {
                    'txt': 'Anna uusi käyttäjänimi!',
                    'className': 'Message-Failure'
                },
            });
            return false;
        }

        this.setState({
            'is_loading': true,
            'msg': null,
        });

        this._getApi().changeUsername({
            'new_username': this.refs.new_username.value,
        }).then((data) => {
            const account_id = data?.account?.id;
            if (account_id) {
                // Change was a success
                window._forceSessionUpdate(data);
                this.setState({
                    'is_loading': false,
                    'msg': {
                        'txt': 'HERMEETTISTÄ! KÄYTTÄJÄNIMI VAIHDETTIIN!',
                        'className': 'Message-Successful'
                    },
                });
                this.props.navigate('/pelaaja');
                return data;
            }
            const error_msg = data?.response?.data?.message || 'Äh, jotain meni vikaa, yritä uudestaan!';
            this.setState({
                'is_loading': false,
                'msg': {
                    'txt': error_msg,
                    'className': 'Message-Failure'
                },
            });
        }).catch((error) => {
            const error_msg = error?.response?.data?.message || 'Äh, jotain meni vikaa, yritä uudestaan!';
            this.setState({
                'is_loading': false,
                'msg': {
                    'txt': error_msg,
                    'className': 'Message-Failure'
                },
            });
            console.dir(error);
        });
        return false;
    }

    onUserInfoChangeFormSubmit(event) {
        event.preventDefault();

        /*if (this.refs.age.value === undefined) {
            // No age provided?!
            this.setState({
                'msg': {
                    'txt': 'Anna uusi käyttäjänimi!',
                    'className': 'Message-Failure'
                },
            });
            return false;
        }*/

        this.setState({
            'is_loading': true,
            'msg': null,
        });

        this._getApi().changeUserInfo({
            'age': this.refs.age.value,
            'computer_specs': this.refs.computer_specs.value,
        }).then((data) => {
            const account_id = data?.account?.id;
            if (account_id) {
                // Change was a success
                window._forceSessionUpdate(data);
                this.setState({
                    'is_loading': false,
                    'msg': {
                        'txt': 'HERMEETTISTÄ! TIETOSI PÄIVITETTIIN!',
                        'className': 'Message-Successful'
                    },
                });
                this.props.navigate('/pelaaja');
                return data;
            }
            const error_msg = data?.response?.data?.message || 'Äh, jotain meni vikaa, yritä uudestaan!';
            this.setState({
                'is_loading': false,
                'msg': {
                    'txt': error_msg,
                    'className': 'Message-Failure'
                },
            });
        }).catch((error) => {
            const error_msg = error?.response?.data?.message || 'Äh, jotain meni vikaa, yritä uudestaan!';
            this.setState({
                'is_loading': false,
                'msg': {
                    'txt': error_msg,
                    'className': 'Message-Failure'
                },
            });
            console.dir(error);
        });
        return false;
    }

    onToggleNewsletterClick(event) {
        event.preventDefault();

        this.setState({
            'is_loading': true,
            'msg': null,
        });

        this._getApi().changeNewsletterSubscription({
            'subscribed': this.refs.subscribed.checked,
        }).then((data) => {
            const account_id = data?.account?.id;
            if (account_id) {
                // Change was a success
                window._forceSessionUpdate(data);
                const subscription_msg = !this.refs.subscribed.checked ? 'HERMEETTISTÄ! Paskatuottaja kirjoittaa sinulle pian!' : 'MYYRÄ! Jos muutat mielesi, tiedät mistä saat taas postia!';
                this.setState({
                    'is_loading': false,
                    'msg': {
                        'txt': `${subscription_msg}`,
                        'className': 'Message-Successful'
                    },
                });
                this.props.navigate('/pelaaja');
                return data;
            }
            const error_msg = data?.response?.data?.message || 'Äh, jotain meni vikaa, yritä uudestaan!';
            this.setState({
                'is_loading': false,
                'msg': {
                    'txt': error_msg,
                    'className': 'Message-Failure'
                },
            });
        }).catch((error) => {
            const error_msg = error?.response?.data?.message || 'Äh, jotain meni vikaa, yritä uudestaan!';
            this.setState({
                'is_loading': false,
                'msg': {
                    'txt': error_msg,
                    'className': 'Message-Failure'
                },
            });
            console.dir(error);
        });
        return false;
    }

    onPasswordChangeFormSubmit(event) {
        event.preventDefault();

        if (!this.refs.old_password.value) {
            // No current password provided?!
            this.setState({
                'msg': {
                    'txt': 'Anna nykyinen salasana!',
                    'className': 'Message-Failure'
                },
            });
            return false;
        }

        if (!this.refs.new_password.value) {
            // No new password provided?!
            this.setState({
                'msg': {
                    'txt': 'Anna uusi salasana!',
                    'className': 'Message-Failure'
                },
            });
            return false;
        }

        if (this.refs.new_password.value !== this.refs.new_password_again.value) {
            // New password and confirmation do NOT match!
            this.setState({
                'msg': {
                    'txt': 'Salamakaan ei iske kahdesti samaan paikkaan, mutta yritä vielä uudelleen kirjoittaa uusi salasana kahdesti samalla tavalla :D',
                    'className': 'Message-Failure'
                },
            });
            return false;
        }

        this.setState({
            'is_loading': true,
            'msg': null,
        });

        this._getApi().changePassword({
            'old_password': this.refs.old_password.value,
            'new_password': this.refs.new_password.value,
        }).then((data) => {
            const account_id = data?.account?.id;
            if (account_id) {
                // Password change was a success
                window._forceSessionUpdate(data);
                this.setState({
                    'is_loading': false,
                    'msg': {
                        'txt': 'HERMEETTISTÄ! SALASANA VAIHDETTIIN!',
                        'className': 'Message-Successful'
                    },
                });
                return data;
            }
            const error_msg = data?.response?.data?.message || 'Äh, jotain meni vikaa, yritä uudestaan!';
            this.setState({
                'is_loading': false,
                'msg': {
                    'txt': error_msg,
                    'className': 'Message-Failure'
                },
            });
        }).catch((error) => {
            const error_msg = error?.response?.data?.message || 'Äh, jotain meni vikaa, yritä uudestaan!';
            this.setState({
                'is_loading': false,
                'msg': {
                    'txt': error_msg,
                    'className': 'Message-Failure'
                },
            });
            console.dir(error);
        });
        return false;
    }

    getEmailChangeModalContents() {
        return (
            <div className="User-Profile-Container">
                <div className="Left-Column"></div>
                <div className="Middle-Column">
                    <h2>Vaihda sähköposti</h2>
                    <div>
                        <label>
                            <span className="User-Info-Field-Title">Uusi sähköposti:</span>
                            <input type="email" ref="new_email" disabled={this.state.is_loading}/>
                        </label>
                    </div>
                    <div>
                        {this._getMessageToDisplay()}
                    </div>
                    {this._getGoBackButton()}

                    <button className="In-Place-Button" onClick={(e) => {
                        this.onEmailChangeFormSubmit(e);
                    }} disabled={this.state.is_loading}>
                        Vaihda sähköposti
                    </button>
                </div>
                <div className="Right-Column"></div>
            </div>
        );
    }

    getUserInfoChangeModalContents() {
        const visitor = this._getVisitor();
        const account = visitor?.account;

        const current_age = account?.age || 0;
        let age_options = [
            ( <option value="0">En halua kertoa</option> )
        ];
        for (let y = (new Date().getFullYear()) - 8; y >= 1900; y--) {
            const age = new Date().getFullYear() - y;
            let chosen_value = null;
            if (current_age === age) {
                chosen_value = 'selected';
            }
            age_options.push(
                ( <option value={y} selected={chosen_value}>{age}</option> )
            )
        }

        return (
            <div className="User-Profile-Container">
                <div className="Left-Column"></div>
                <div className="Middle-Column">
                    <h2>Päivitä tietosi</h2>
                    <div>
                        <label>
                            Ikä: <select ref="age" disabled={this.state.is_loading}>{age_options}</select>
                        </label>
                    </div>
                    <div>
                        <label>
                            Tietokoneen speksit:<br/>
                            <textarea ref="computer_specs"
                                      disabled={this.state.is_loading} rows="8" style={{width: '400px'}}>{account?.computer_specs}</textarea>
                        </label>
                    </div>

                    <div>
                        {this._getMessageToDisplay()}
                    </div>
                    {this._getGoBackButton()}
                    <button className="In-Place-Button" onClick={(e) => {
                        this.onUserInfoChangeFormSubmit(e);
                    }} disabled={this.state.is_loading}>
                        Tallenna
                    </button>
                </div>
                <div className="Right-Column"></div>
            </div>
        );
    }

    getUsernameChangeModalContents() {
        return (
            <div className="User-Profile-Container">
                <div className="Left-Column"></div>
                <div className="Middle-Column">
                    <h2>Vaihda käyttäjänimi</h2>
                    <div>
                        <label>
                            <span className="User-Info-Field-Title">Uusi käyttäjänimi:</span>
                            <input type="text" ref="new_username" disabled={this.state.is_loading}/>
                        </label>
                    </div>
                    <div>
                        {this._getMessageToDisplay()}
                    </div>
                    {this._getGoBackButton()}

                    <button className="In-Place-Button" onClick={(e) => {
                        this.onUsernameChangeFormSubmit(e);
                    }} disabled={this.state.is_loading}>
                        Vaihda käyttäjänimi
                    </button>
                </div>
                <div className="Right-Column"></div>
            </div>
        );
    }

    getPasswordChangeModalContents() {
        return (
            <div className="User-Profile-Container">
                <div className="Left-Column"></div>
                <div className="Middle-Column">
                    <h2>Vaihda salasana</h2>
                    <div>
                        <label>
                            <span className="User-Info-Field-Title" style={{minWidth: '250px'}}>
                                Nykyinen salasana:
                            </span>
                            <input type="password" ref="old_password"
                                                      disabled={this.state.is_loading}/>
                        </label>
                    </div>
                    <div>
                        <label>
                            <span className="User-Info-Field-Title" style={{minWidth: '250px'}}>
                                Uusi salasana:
                            </span>
                            <input type="password" ref="new_password" disabled={this.state.is_loading}/>
                        </label>
                    </div>
                    <div>
                        <label>
                            <span className="User-Info-Field-Title" style={{minWidth: '250px'}}>
                                Uusi salasana, varmistus:
                            </span>
                            <input type="password" ref="new_password_again" disabled={this.state.is_loading}/>
                        </label>
                    </div>

                    <div>
                        {this._getMessageToDisplay()}
                    </div>
                    {this._getGoBackButton()}
                    <button className="In-Place-Button" onClick={(e) => {
                        this.onPasswordChangeFormSubmit(e);
                    }} disabled={this.state.is_loading}>Vaihda salasana
                    </button>
                </div>
                <div className="Right-Column"></div>
            </div>
        );
    }

    _getGoBackButton() {
        return (
            <button className="In-Place-Button" onClick={(e) => {
                this.onGoBackClick(e);
            }} disabled={this.state.is_loading} style={{'float': 'right'}}>
                Takaisin
            </button>
        );
    }

    availableProfileSubtabs() {
        return {
            'profile': {label: 'Profiili'},
            'computer_spec': {label: 'Kone spec'},
        };
    }

    onSubtabSelectClick(e, subtab) {
        this.setState({
            profile_subtab: subtab,
        });
    }

    getProfileSubtabs() {
        const current_subtab = this.state?.profile_subtab;
        const available_subtabs = this.availableProfileSubtabs();
        let subtabs = [];
        for (const handle in available_subtabs) {
            let css_class = ['Profile-SubTab'];
            if (current_subtab === handle) {
                css_class.push('Active-Profile-SubTab');
            }
            subtabs.push((
                <>
                    <li className={css_class.join(' ')} onClick={(e) => { this.onSubtabSelectClick(e, handle); }}>{available_subtabs[handle].label}</li>
                </>
            ));
        }
        return (
            <>
                <ul>
                    {subtabs}
                </ul>
            </>
        );
    }

    getProfileAvatar(account) {
        if (!account?.img) {
            return null;
        }
        return (
            <div className="User-Profile-Image">
                <img src={account.img} onClick={(event) => {
                    this.onProfileImageClick(event);
                }}/>
            </div>
        );
    }

    getDiscordSsoElement(visitor) {
        // <!-- @see https://www.miniorange.com/iam/login-with-external-idp/configure-discord-sso -->
        if (!visitor.account?.sso?.DISCORD) {
            return (
                <span className="Discord-Sso">
                    <img src={discordLogo} alt="Discord" style={{height: '23px'}} />
                    <a href="" onClick={(event) => {
                        this.onDiscordLoginClick(event);
                    }}>
                        <FontAwesomeIcon icon={icon({name: 'external-link-alt'})}/>
                    </a>
                </span>
            );
        } else if (visitor.account?.sso?.DISCORD) {
            return (
                <span className="Discord-Sso">
                    <img src={discordLogo} alt="Discord" style={{height: '23px'}} />
                    <FontAwesomeIcon icon={icon({name: 'check'})}/>
                </span>
            );
        }
        return null;
    }

    getTwitchSsoElement(visitor) {
        if (!visitor.account?.sso?.TWITCH) {
            return (
                <span className="Twitch-Sso">
                    <img src={twitchLogo} alt="Twitch"/>
                    <a href="" onClick={(event) => {
                        this.onTwitchLoginClick(event);
                    }}>
                        <FontAwesomeIcon icon={icon({name: 'external-link-alt'})}/>
                    </a>
                </span>
            );
        } else if (visitor.account?.sso?.TWITCH) {
            return (
                <span className="Twitch-Sso">
                    <img src={twitchLogo} alt="Twitch" />
                    <FontAwesomeIcon icon={icon({name: 'check'})}/>
                </span>
            );
        }
        return null;
    }

    getUsernameToDisplay(visitor) {
        return visitor.account?.username ? visitor.account.username : `${visitor.anonymous_name}`; // (#${visitor.anonymous_name_id})
    }

    getEmailSection(visitor) {
        const field_title_element = (
            <span className="User-Info-Field-Title">Sähköposti:</span>
        );
        let email_display_value = (
            <>
                (ei asetettu)
            </>
        );
        let email_section = null;
        let newsletter_section = null;
        if (visitor.account?.email_validated) {
            newsletter_section = (
                <label>
                    <input type="checkbox" ref="subscribed" checked={visitor.account?.newsletter ? 'checked' : ''}
                           onClick={(event) => {
                               this.onToggleNewsletterClick(event);
                           }} disabled={this.state.is_loading} /> Tilaa uutiskirje
                </label>
            );
        }
        if (visitor.account?.email) {
            email_display_value = visitor.account?.email;
        }
        email_section = (
            <>
                {field_title_element}
                <a href="" onClick={(event) => {
                    this.onEmailChangeClick(event);
                }}>{email_display_value}</a>
                {visitor.account?.email ? newsletter_section : null}
            </>
        );
        let email_warning = null;
        if (visitor.account?.email && !visitor.account?.email_validated) {
            email_warning = (
                <WarningNote title="Sähköpostiosoitteesi on vahvistettava!" mode="warning" />
            );
        }
        return (
            <>
                <div>
                    {email_section}
                </div>
                <div>
                    {email_warning}
                </div>
            </>
        );
    }

    static getUserStats(visitor) {
        if (!visitor.account?.statistics) {
            return null;
        }
        const tags = UserTags.getTags(visitor);
        return (
            <>
                <small>
                    <FontAwesomeIcon icon={icon({name: 'pen-nib'})} /> {visitor.account.statistics.articles}&nbsp;
                    <FontAwesomeIcon icon={icon({name: 'comment'})} /> {visitor.account.statistics.comments}&nbsp;
                    <FontAwesomeIcon icon={icon({name: 'thumbs-up'})} /> {visitor.account.statistics.thumbs_up}&nbsp;
                    <FontAwesomeIcon icon={icon({name: 'thumbs-down'})} /> {visitor.account.statistics.thumbs_down}&nbsp;
                    <br />
                    {tags}
                </small>
            </>
        );
    }

    getProfileSubtabContents(visitor) {
        const current_subtab = this.state?.profile_subtab;
        if (current_subtab === 'computer_spec') {
            return (
                <>
                    <h3>Koneen speksit</h3>
                    <div>
                        <a
                            href="" onClick={(event) => {
                            this.onAccountInfoChangeClick(event);
                        }}>{visitor.account?.computer_specs ? visitor.account.computer_specs : 'Leivänpaahdin?'}</a>
                    </div>
                </>
            );
        }
        // Profile subtab
        const username = this.getUsernameToDisplay(visitor);
        const email_section = this.getEmailSection(visitor);
        return (
            <>
            <div>
                    <span className="User-Info-Field-Title">Käyttäjänimi:</span> <a href="" onClick={(event) => {
                        this.onUsernameChangeClick(event);
                    }}>{username}</a>
                </div>
                <div>
                    <span className="User-Info-Field-Title">Salasana:</span> <a href="" onClick={(event) => {
                        this.onPasswordChangeClick(event);
                    }}>*******</a>
                </div>
                <hr/>
                { /** NIMI? */ }
                <div>
                    <span className="User-Info-Field-Title">Ikä:</span> <a href="" onClick={(event) => {
                        this.onAccountInfoChangeClick(event);
                    }}>{visitor.account?.age ? visitor.account.age : '-'}</a>
                </div>
                { /** SUKUPUOLI? */}
                { /** ASUINPAIKKA? */}
                {email_section}
                { /** PUHELIN? */}
            </>
        );
    }

    getRegisteredUserModalContents() {
        const visitor = this._getVisitor();
        const account = visitor?.account;
        if (!account) {
            return (<div/>)
        }
        const profile_image = this.getProfileAvatar(account);
        const twitch_sso_link = this.getTwitchSsoElement(visitor);
        const discord_sso_link = this.getDiscordSsoElement(visitor);
        const username = this.getUsernameToDisplay(visitor);
        const stats = UserModal.getUserStats(visitor);

        return (
            <>
                {this._getMessageToDisplay()}
                <div className="User-Profile-Container">
                    <div className="Left-Column">
                        <h2>{username}</h2>
                        {profile_image}
                        {stats}

                        {this.getProfileSubtabs()}
                    </div>
                    <div className="Middle-Column">
                        {this.getProfileSubtabContents(visitor)}
                    </div>
                    <div className="Right-Column">
                        <a href="" className="In-Place-Button" onClick={(event) => {
                            this.onLogoutClick(event);
                        }}>Kirjaudu ulos</a>

                        <p>Yhdistä tunnus:</p>
                        <ul>
                            <li>{twitch_sso_link}</li>
                            <li>{discord_sso_link}</li>
                            <li>{ /** STEAM SSO? */ }</li>
                        </ul>
                    </div>
                </div>
            </>
        ); // <!-- li>Poista tili</li -->
    }

    exchangeTwitchoAuthCode() {
        if (this.state.sso) {
            // SSO already completed in this session
            return;
        }
        const params = new URLSearchParams(window.location.search);
        const code = params.get('code');
        const scope = params.get('scope');
        const state = params.get('state');
        if (!code) {
            return;
        }
        this.setState({
            'is_loading': true,
            'msg': null,
            'sso': true,
        });

        this._getApi().ssoLogin('TWITCH', code, scope, state).then((data) => {
            const account_id = data?.account?.id;
            if (account_id) {
                // Login was a success!
                window._forceSessionUpdate(data);
                this.setState({
                    'is_loading': false,
                    'msg': {
                        'txt': 'HERMEETTISTÄ! TWITCH-TILI ON NYT YHDISTETTY KÄYTTÄJÄÄSI!',
                        'className': 'Message-Successful'
                    },
                });
                this.props.navigate('/pelaaja');
                return data;
            }
            const error_msg = data?.response?.data?.message || 'Äh, jotain meni vikaa, yritä uudestaan!';
            this.setState({
                'is_loading': false,
                'msg': {
                    'txt': error_msg,
                    'className': 'Message-Failure'
                },
            });
        }).catch((error) => {
            const error_msg = error?.response?.data?.message || 'Äh, jotain meni vikaa, yritä uudestaan!';
            this.setState({
                'is_loading': false,
                'msg': {
                    'txt': error_msg,
                    'className': 'Message-Failure'
                },
            });
            console.dir(error);
        });
    }

    exchangeDiscordoAuthCode() {
        if (this.state.sso_discord) {
            // SSO already completed in this session
            return;
        }
        const params = new URLSearchParams(window.location.search);
        const code = params.get('code');
        const state = params.get('state');
        if (!code) {
            return;
        }
        this.setState({
            'is_loading': true,
            'msg': null,
            'sso_discord': true,
        });

        this._getApi().ssoLogin('DISCORD', code, null, state).then((data) => {
            const account_id = data?.account?.id;
            if (account_id) {
                // Login was a success!
                window._forceSessionUpdate(data);
                this.setState({
                    'is_loading': false,
                    'msg': {
                        'txt': 'HERMEETTISTÄ! DISCORD-TILI ON NYT YHDISTETTY KÄYTTÄJÄÄSI!',
                        'className': 'Message-Successful'
                    },
                });
                this.props.navigate('/pelaaja');
                return data;
            }
            const error_msg = data?.response?.data?.message || 'Äh, jotain meni vikaa, yritä uudestaan!';
            this.setState({
                'is_loading': false,
                'msg': {
                    'txt': error_msg,
                    'className': 'Message-Failure'
                },
            });
        }).catch((error) => {
            const error_msg = error?.response?.data?.message || 'Äh, jotain meni vikaa, yritä uudestaan!';
            this.setState({
                'is_loading': false,
                'msg': {
                    'txt': error_msg,
                    'className': 'Message-Failure'
                },
            });
            console.dir(error);
        });
    }

    render() {
        const visitor = this._getVisitor();
        if (!visitor || !visitor.id) {
            return (
                <div>
                    Failed!
                </div>
            )
        }
        let modal_contents;
        switch (this._getView()) {
            case 'twitch':
                this.exchangeTwitchoAuthCode();
                modal_contents = (<div>Latailee..</div>);
                break;
            case 'discord':
                this.exchangeDiscordoAuthCode();
                modal_contents = (<div>Latailee..</div>);
                break;
            case 'password': // Password change dialog
                if (!visitor.account) { // Nothing to do for unauthenticated
                    this.onGoBackClick();
                    break;
                }
                // Authenticated
                modal_contents = this.getPasswordChangeModalContents();
                break;
            case 'email': // Email change dialog
                if (!visitor.account) { // Nothing to do for unauthenticated
                    this.onGoBackClick();
                    break;
                }
                // Authenticated
                modal_contents = this.getEmailChangeModalContents();
                break;
            case 'username': // Username change dialog
                if (!visitor.account) { // Nothing to do for unauthenticated
                    this.onGoBackClick();
                    break;
                }
                // Authenticated
                modal_contents = this.getUsernameChangeModalContents();
                break;
            case 'info': // User information change dialog
                if (!visitor.account) { // Nothing to do for unauthenticated
                    this.onGoBackClick();
                    break;
                }
                // Authenticated
                modal_contents = this.getUserInfoChangeModalContents();
                break;
            case 'logged-out': // Logout dialog
                modal_contents = (<div>
                    <h2>Heippa!</h2>
                    <p>
                        <img src={websiteBaseUrl + 'logout_perk_fun_512.png'} alt="CHARACTER.FUN - 1" style={{width: '345px'}} />
                    </p>
                </div>);
                break;
            case 'login-form':
                // Unauthenticated
                modal_contents = this.getLoginFormContents();
                break;
            default:
                if (visitor.account) { // Authenticated
                    modal_contents = this.getRegisteredUserModalContents();
                    break;
                }
                // Unauthenticated
                modal_contents = this.getVisitorModalContents();
                break;
        }

        const return_uri = localStorage.getItem(`signup_return_uri`) || '/';
        return (
            <div className="Modal-Wrapper Common-Modal" onClick={(e) => {
                    e.preventDefault();
                    localStorage.removeItem(`signup_return_uri`);
                    this.props.navigate(return_uri);
                }}>
                <div className="UserModal" onClick={e => e.stopPropagation()}>
                    {modal_contents}
                </div>
            </div>
        );
    }
}

function WithNavigateAndContext(props) {
    let navigate = useNavigate();
    return (<AppContext.Consumer>
        {(context) => {
            return <UserModal {...props} navigate={navigate} context={context} />
        }}
    </AppContext.Consumer>)
}

export default WithNavigateAndContext;
