import './BroadcastBox.css';
import React from 'react';
import {AppContext} from "../App";
import WebsiteApi from "../WebsiteApi";
import {icon} from "@fortawesome/fontawesome-svg-core/import.macro";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useNavigate} from "react-router-dom";

class BroadcastBox extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            'broadcasts': null,
            'is_loading': true,
        };
        this._getPastBroadcasts();
    }

    _getApi() {
        return new WebsiteApi();
    }

    _getChoppedBroadcastTitle(title) {
        return title.split('|').map((item, key) => {
            return (
                <span key={key}>
                    {item}<br/>
                </span>
            );
        });
    }

    _getPastBroadcastsContent() {
        const broadcasts = this.state?.broadcasts;
        if (!broadcasts) {
            return (<div />)
        }
        let broadcast_elements = [];
        for (const broadcast of broadcasts) {
            if (broadcast_elements.length === 3) {
                break;
            }
            broadcast_elements.push(
                (
                    <div className="Broadcast-Box-Past-Broadcast" style={{'backgroundImage': 'url(https://bingo.pelaajat.com/vod-api.php?action=thumbnail:vod&id=' + broadcast.id + ')'}} onClick={(e) => {
                        e.preventDefault();
                        window.location.href = broadcast.url;
                    }}>
                        <a href={broadcast.url} target="_blank">
                            {this._getChoppedBroadcastTitle(broadcast.title)}
                        </a>
                        <label className="Label Broadcast-Date">
                            {broadcast.when}
                        </label>
                        <label className="Label Broadcast-Duration">
                            {broadcast.duration}
                        </label>
                    </div>
                )
            ); // <FontAwesomeIcon icon={icon({name: 'user'})} /> {broadcast.views} /
        }
        return broadcast_elements;
    }

    _getPastBroadcasts() {
        this._getApi().getPastBroadcasts().then((data) => {
            this.setState({
                'broadcasts': data,
                'is_loading': false,
            });
        });
    }

    _getLiveBroadcast() {
        return this.props?.context?.components?.broadcast;
    }

    getLiveBroadcastBox() {
        const broadcast = this._getLiveBroadcast();
        if (!broadcast?.is_live) {
            return (
                <div />
            )
        }
        const img_url = broadcast.img?.large;
        return (
            <div>
                <a href="https://www.twitch.tv/pelaajatcom" target="_blank">
                    <img src={img_url} className="Broadcast-Box-Cover-Image" alt={broadcast.txt} />
                </a>
                <label className="Label Broadcast-Box-Live">
                        <a href="https://www.twitch.tv/pelaajatcom">LIVE
                            <FontAwesomeIcon icon={icon({name: 'twitch', style: 'brands'})} style={{color: "#fff",}} />
                    </a>
                </label>
                <h2>
                    {this._getChoppedBroadcastTitle(broadcast.txt)}
                    <small>
                        <FontAwesomeIcon icon={icon({name: 'user'})} style={{color: "#ff3300",}} />
                        {broadcast.viewers}
                    </small>
                </h2>
            </div>
        )
    }

    render() {
        if (this.props?.live) {
            const broadcast = this._getLiveBroadcast();
            if (!broadcast?.is_live) {
                return null;
            }
            return (
                <div className="Broadcast-Box-Wrapper">
                    <div className="Broadcast-Box">
                        {this.getLiveBroadcastBox()}
                    </div>
                </div>
            );
        }
        return (
            <div className="Broadcast-Box-Wrapper With-Margins">
                <h2 className="Broadcast-Box-Title">Lähetykset</h2>
                <div className="Broadcast-Box">
                    <div className="Broadcast-Box-Past">
                        {this._getPastBroadcastsContent()}
                    </div>
                </div>
            </div>
        );
    }
}

const BroadcastBoxWithContext = (props) => {
    const navigate = useNavigate();
    return (<AppContext.Consumer>
        {(context) => {
            return <BroadcastBox {...props} navigate={navigate} context={context} />
        }}
    </AppContext.Consumer>)
};
export default BroadcastBoxWithContext;
