import './ContentColumn.css';
import React from 'react';

class ContentColumn extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        if (this.props.msg) {
            return (
                <div className="Content-Column">
                    <div
                        dangerouslySetInnerHTML={{
                            __html: this._props.msg,
                        }} />
                </div>
            )
        }
        return (
            <div className="Content-Column">
                {this.props.content}
            </div>
        )
    }
}

export default ContentColumn;
