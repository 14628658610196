import './BroadcastInfo.css';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import {AppContext} from "../App";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro'
import twitchLogo from '../images/twitch_logo.png'

class BroadcastInfo extends React.Component {
    constructor(props) {
        super(props);
    }

    _getBroadcast() {
        return this.props?.context?.components?.broadcast;
    }

    getLiveBadge() {
        const broadcast = this._getBroadcast();
        if (broadcast?.is_live) {
            return (
                <label>
                    <a href="https://www.twitch.tv/pelaajatcom" target="_blank">LIVE</a>
                </label>
            )
        }
        if (broadcast?.schedule) {
            return (
                <span>{broadcast.schedule}</span>
            )
        }
    }

    getTwitchBadge() {
        const broadcast = this._getBroadcast();
        if (broadcast?.is_live) {
            return (
                <img src={twitchLogo} alt="Twitch" />
            );
            /*return (
                <FontAwesomeIcon icon={icon({name: 'twitch', style: 'brands'})} beatFade style={{color: "#ff3300",}} />
            )*/
        }
        return (
            <img src={twitchLogo} alt="Twitch" />
        );
        /*
        return (
            <FontAwesomeIcon icon={icon({name: 'twitch', style: 'brands'})} style={{color: "#ff3300",}} />
        )*/
    }

    openBroadcast(event) {
        const broadcast = this._getBroadcast();
        event.preventDefault();
        if (broadcast?.is_live) {
            window.open('https://www.twitch.tv/pelaajatcom', '_blank');
            return;
        }
        if (broadcast?.link) {
            this.props.navigate(broadcast.link);
            return;
        }
        console.log('Oops.. ei linkkiä!');
    }

    render() {
        const broadcast = this._getBroadcast();
        if (!broadcast) {
            return (<div />)
        }
        return (
            <div className="Broadcast-Info" onClick={(event) => { this.openBroadcast(event); }}>
                {this.getLiveBadge()}
                <div className="Title-Scroll">{broadcast.txt}</div>
                <a href="https://www.twitch.tv/pelaajatcom" target="_blank">
                    {this.getTwitchBadge()}
                </a>
            </div>
        );
    }
}

const WithNavigateAndContext = (props) => {
    let navigate = useNavigate();
    return (<AppContext.Consumer>
        {(context) => {
            return <BroadcastInfo {...props} navigate={navigate} context={context} />
        }}
    </AppContext.Consumer>)
};
export default WithNavigateAndContext;
