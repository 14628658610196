import './TournamentBox.css';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import {AppContext} from "../App";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {icon} from "@fortawesome/fontawesome-svg-core/import.macro";
import liquipediaLogo from "../images/liquipedia_logo.png";
import finnishFlagTabIcon from "../images/finnish_flag_tab_icon.png";
import twitchBubbleTabIcon from "../images/twitch_bubble_icon.png";
import topTierTabIcon from "../images/top_tier_icon.png";
import tournamentsTabIcon from "../images/turnaukset_icon.png";
import WebsiteApi from "../WebsiteApi";

class TournamentBox extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            'fullpage': this.props?.fullpage,
            'is_loading': true,
            'active_tab': 'finnish',
            'tournament_filter': null,
        };
    }

    _getApi() {
        return new WebsiteApi();
    }

    _getMatchList(match_list) {
        if (match_list) {
            return match_list;
        }
        if (this.state?.fullpage && this.props.context?.matches) {
            return this.props.context.matches;
        }
        return this.props.context?.components?.matches;
    }

    _getMatchContent(match) {
        let home_score = null;
        let away_score = null;
        if (match?.score) {
            const scores = match.score.split(':');
            home_score = scores[0];
            away_score = scores[1];
        }
        let date_divider = ''; // New way
        if (match?.date_separator) {
            date_divider = (
                <div className="Calendar-Match-Date-Title">{match.date_separator}</div>
            );
        } else if (match?.tomorrow) {
            date_divider = (
                <div className="Calendar-Match-Date-Title">— HUOMENNA —</div>
            );
        }

        let twitch_link = '';
        if (0) { // @todo
            twitch_link = (
                <a href="https://www.twitch.tv/pelaajatcom" target="_blank">
                    <FontAwesomeIcon icon={icon({name: 'twitch', style: 'brands'})} style={{color: "#ff3300",}}/>
                </a>
            );
        }
        let logo_url = '';
        if (match?.tournament?.logo_url) {
            logo_url = (
                <img src={match.tournament.logo_url} className="Tournament-Logo" />
            );
        }
        let tournament_name = '';
        if (match?.tournament?.alias) {
            tournament_name = (
                <>
                    {logo_url} {match.tournament.alias}
                </>
            );
        } else if (match?.tournament?.name) {
            tournament_name = (
                <>
                    {logo_url} {match.tournament.name}
                </>
            );
            if (match.tournament?.uri) {
                tournament_name = (
                    <a href={match.tournament?.uri} onClick={(e) => {
                        e.preventDefault();
                        this.props.navigate(match.tournament.uri);
                    }}>
                        {tournament_name}
                    </a>
                );
            }
        }
        // removed from below {match['game']}
        let game_n_tournament = (
            <>
                {tournament_name}
            </>
        );
        let home_team_name = match['home_team']['name'];
        if (match['home_team']['name'] === 'TBD') {
            home_team_name = (
                <>
                    <div className="TBD-Team"></div>
                    (ei vielä selvillä)
                </>
            );
        }
        if (match['home_team']['logo_url']) {
            home_team_name = (
                <>
                    <img src={match['home_team']['logo_url']} className="Match-Team-Logo"/> {match['home_team']['name']}
                </>
            );
        }
        if (match['home_team']['uri']) {
            home_team_name = (
                <>
                    <a href={match['home_team']['uri']} onClick={(e) => {
                        e.preventDefault();
                        this.props.navigate(match['home_team']['uri']);
                    }}>
                        {home_team_name}
                    </a>
                </>
            );
        }
        if (match?.home_team?.nationality === 'fi') {
            home_team_name = (
                <>
                    {home_team_name}
                    <div className="Finnish-Flag"></div>
                </>
            );
        }

        let away_team_name = match['away_team']['name'];
        if (match['away_team']['name'] === 'TBD') {
            away_team_name = (
                <>
                    <div className="TBD-Team"></div>
                    (ei vielä selvillä)
                </>
            );
        }
        if (match['away_team']['logo_url']) {
            away_team_name = (
                <>
                    <img src={match['away_team']['logo_url']} className="Match-Team-Logo"/> {match['away_team']['name']}
                </>
            );
        }
        if (match['away_team']['uri']) {
            away_team_name = (
                <>
                    <a href={match['away_team']['uri']} onClick={(e) => {
                        e.preventDefault();
                        this.props.navigate(match['away_team']['uri']);
                    }}>
                        {away_team_name}
                    </a>
                </>
            );
        }
        if (match?.away_team?.nationality === 'fi') {
            away_team_name = (
                <>
                    {away_team_name}
                    <div className="Finnish-Flag"></div>
                </>
            );
        }

        let air_time_or_score = null;
        if (home_score !== null && away_score !== null) {
            air_time_or_score = (
                <>
                    <strong className="Match-Score">{home_score}<br/>{away_score}</strong>
                </>
            );
        } else {
            air_time_or_score = (
                <strong className="Match-AirTime">{match['airs']}</strong>
            );
        }

        let match_icons = [];
        if (this.props?.fullpage && match.finnish_match) {
            match_icons.push((<img src={finnishFlagTabIcon} alt="Suomipeli" title="Suomipeli"/>));
        }
        if (match_icons.length > 0) {
            match_icons = (
                <div className="Tournament-Match-Icons">
                    {match_icons}
                </div>
            );
        }

        return (
            <>
                {date_divider}

                <div className="Calendar-Match" style={{cursor: (match.uri ? 'pointer' : '')}} onClick={(e) => {
                    e.preventDefault();
                    if (!match.uri) {
                        return;
                    }
                    this.props.navigate(match.uri);
                }}>

                    <span className="Calendar-Match-Title">
                        {home_team_name}<br/>
                        {away_team_name}
                    </span>

                    <label className="Tournament-Info">
                        {game_n_tournament}
                    </label>

                    {air_time_or_score}

                    {match_icons}

                    {twitch_link}
                </div>
            </>
        );
        // <span className="Divider"> | </span>
    }

    getMatches(filter, match_list) {
        match_list = this._getMatchList(match_list);
        if (!match_list) {
            return (<></>);
        }
        let matches = [];
        for (let match of match_list) {
            if (filter) {
                if (!filter(match)) {
                    continue;
                }
            }
            matches.push(this._getMatchContent(match));
        }
        return matches;
    }

    openUri(e, uri) {
        e.preventDefault();
        if (this.state?.fullpage) {
            return;
        }
        this.props.navigate(uri);
    }

    _getTournamentRow(tournament) {
        if (!tournament?.logo_url) {
            return null;
        }
        const logo = (
            <img className="Tournament-Logo" src={tournament.logo_url} alt={tournament.name} title={tournament.name} />
        );
        let element_className = "Tournament-Filter-Link";
        if (this.state?.tournament_filter === tournament.id) {
            element_className += ' Tournament-Filter-Link-Active';
        }
        return (
            <>
                <a href="" className={element_className} onClick={(e) => { this.onTournamentFilterChangeClick(e, tournament.id); }}>{logo}</a>
            </>
        );
    }

    _getTournamentName() {
        if (!this.state?.tournament_filter) {
            return null;
        }
        if (!this.props.context?.components?.tournaments) {
            return null;
        }
        for (let tournament of this.props.context.components.tournaments) {
            if (tournament.id == this.state.tournament_filter) {
                return tournament?.name || null;
            }
        }
        return null;
    }

    getTournaments() {
        let tournaments = null;
        if (!this.props.context?.components?.tournaments) {
            return tournaments;
        }
        let tournament_list = [];
        for (let tournament of this.props.context.components.tournaments) {
            tournament_list.push(this._getTournamentRow(tournament));
        }
        let tournament_title = this._getTournamentName();
        if (tournament_title) {
            tournament_title = (
                <h2 className="Tournament-Title">{tournament_title}</h2>
            );
        }
        let matches = (<>Latailee..</>);
        if (this?.state?.tournament_matches !== null) {
            matches = [];
            for (let tournament_match of this.state.tournament_matches) {
                matches.push(this._getMatchContent(tournament_match));
            }
            if (matches.length === 0) {
                matches = (<>(ei otteluita tiedossa)</>);
            }
        }

        tournaments = (
            <>
                <div className="Tournament-Filter-Links-Wrapper">
                    {tournament_list}
                </div>
                {tournament_title}

                {matches}
            </>
        );
        return tournaments;
    }

    onTabChangeClick(e, tab) {
        this.setState({
            active_tab: tab.id,
        });

        if (tab.id === 'tournaments' && !this.state?.tournament_filter) {
            if (this.props?.context?.components?.tournaments[0]?.id) {
                // Set first tournament as selected (if nothing has been chosen)
                const tournament_id = this.props.context.components.tournaments[0].id;
                this.setState({
                    tournament_filter: tournament_id,
                    tournament_matches: null,
                });
                this.onTournamentFilterChangeClick(e, tournament_id); // --> Load tournament data
            }
        }
    }

    onTournamentFilterChangeClick(e, tournament_id) {
        e.preventDefault();
        this.setState({
            tournament_filter: tournament_id,
            tournament_matches: null,
        });

        this._getApi().getTournamentMatches(tournament_id).then((data) => {
            this.setState({
                tournament_matches: data,
            });
        }); // @todo: HANDLE ERRORS
        return false;
    }

    render() {
        if (this.props?.fullpage) {
            let tabs_element = [];
            const tabs = [
                {
                    id: 'finnish', 'label': 'Suomalaiset', 'icon': finnishFlagTabIcon, 'content': () => {
                        let match_list;
                        if (this.state?.fullpage && this.props.context?.finnish_matches) {
                            // Override normal list of matches to look for on tournament index
                            match_list = this.props.context.finnish_matches;
                            if (!match_list || match_list.length === 0) {
                                return (
                                    <>
                                        Ei suomipelejä tiedossa
                                    </>
                                );
                            }
                        }
                        return this.getMatches((match) => {
                            if (match?.home_team?.nationality === 'fi') {
                                return true;
                            }
                            if (match?.away_team?.nationality === 'fi') {
                                return true;
                            }
                            return match?.finnish_match;
                        }, match_list);
                    }
                },
                /*{
                    id: 'pelcom', 'label': 'Pelaajat.com', 'icon': twitchBubbleTabIcon, 'content': () => {
                        let match_list;
                        if (this.state?.fullpage && this.props.context?.broadcasts) {
                            // Override normal list of matches to look for on tournament index
                            match_list = this.props.context.broadcasts;
                            if (!match_list || match_list.length === 0) {
                                return (
                                    <>
                                        Ei lähetyksiä tiedossa
                                    </>
                                );
                            }
                        }
                        return this.getMatches(null, match_list);
                    }
                },*/
                {
                    id: 'top', 'label': 'Top Tier', 'icon': topTierTabIcon, 'content': () => {
                        let match_list;
                        if (this.state?.fullpage && this.props.context?.top_matches) {
                            // Override normal list of matches to look for on tournament index
                            match_list = this.props.context.top_matches;
                        }
                        return this.getMatches(null, match_list);
                    }
                },
                {
                    id: 'tournaments', 'label': 'Turnaukset', 'icon': tournamentsTabIcon, 'content': () => {
                        return this.getTournaments();
                    }
                },
            ];
            let tab_content = null;
            for (let tab of tabs) {
                let tab_className = null;
                if (tab?.id === this.state?.active_tab) {
                    tab_className = 'Tournament-Filter-Tab-Active';
                    if (tab?.content) {
                        tab_content = tab.content();
                    }
                }
                tabs_element.push((
                    <>
                        <li onClick={(e) => {
                            this.onTabChangeClick(e, tab);
                        }} className={tab_className}>
                            <img src={tab.icon} alt={tab.label} className={'Tournament-Tab-Icon-' + tab.id} /> {tab.label}
                        </li>
                    </>
                ));
            }

            return (
                <div className="Calendar-Box">
                    <ul className="Tournament-Filter-Tabs">
                        {tabs_element}
                    </ul>
                    {tab_content}
                    <div className="Calendar-Attribution">
                        Kalenterin tarjoaa: <a href="https://liquipedia.net/" target="_blank">Liquipedia</a> <img
                        src={liquipediaLogo} alt="Liquipedia" className="Liquipedia-Logo"/>
                    </div>
                </div>
            )
        }
        return (
            <div className="Calendar-Box">
                <h2 className="Calendar-Title" onClick={(e) => { this.openUri(e, '/turnaukset'); }}>Kalenteri</h2>
                {this.getMatches()}

                <div className="Calendar-Attribution">
                    Kalenterin tarjoaa: <a href="https://liquipedia.net/" target="_blank">Liquipedia</a> <img src={liquipediaLogo} alt="Liquipedia" className="Liquipedia-Logo" />
                </div>
            </div>
        )
    }
}

const TournamentBoxWithContext = (props) => {
    let navigate = useNavigate();
    return (<AppContext.Consumer>
        {(context) => {
            return <TournamentBox {...props} navigate={navigate} context={context} />
        }}
    </AppContext.Consumer>)
};

export default TournamentBoxWithContext;

