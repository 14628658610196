import './PageCommon.css';
import React from 'react';
import {useParams} from "react-router";
import {useNavigate} from "react-router-dom";
import {AppContext} from "../App";
import {NewsPage} from "./NewsPage";

class PelShop extends NewsPage {
    constructor(props) {
        super(props);
        this.resource_type = 'product';
        this.componentDidUpdate({}, {}, {});
    }

    getResourceTypeDisplayName(form) {
        const forms = ['Tuote-sivu', 'Tuote-sivua', 'PelShop'];
        return forms[form];
    }
}

const PelShopWithContext = (props) => {
    const { uri } = useParams();
    const navigate = useNavigate();
    return (<AppContext.Consumer>
        {(context) => {
            return <PelShop {...props} navigate={navigate} context={context} uri={uri} />
        }}
    </AppContext.Consumer>)
};
export default PelShopWithContext;
