
const websiteBaseUrl = (process.env.REACT_APP_BASE_URL || '/').replace(/\/$/, '');

class MarkupTools {
    static Linkify(text) {
        if (!text || !text.replace) {
            return text;
        }
        // Linkify categories
        const categoryRegex = /(#\b[a-z0-9\-]+\b)/ig;
        text = text.replace(categoryRegex, function(category) {
            if (!category) {
                return category;
            }
            return '<a href="' + websiteBaseUrl + '/kategoria/' + ('' + category).replace('#', '') + '">' + category + '</a>';
        });
        // @todo: LINKIFY OTHER THINGS, E.G. @MENTIONS AND OTHER THINGS?
        return text;
        /*const urlRegex = /(\bhttps:\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/ig;
        return text.replace(urlRegex, function(url) {
            return '<a href="' + url + '" target="_blank" rel="noopener noreferrer">' + url.replace('https://', '') + '</a>';
        });*/
    }
}

export default MarkupTools;
