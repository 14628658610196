import './CommunityFeedBox.css';
import React from 'react';
import {AppContext} from "../App";
import {Link, useNavigate} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {icon} from "@fortawesome/fontawesome-svg-core/import.macro";
import Loading from "../Loading";

class CommunityFeedBox extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            'fullpage': this.props?.fullpage,
            'is_loading': false,
        };
    }

    _getFeed() {
        if (!this.state?.fullpage && this.props.context?.community) {
            let community_feed = this.props.context.community;
            return community_feed.slice(0, 5)
        }
        return this.props.context?.community;
    }

    openResource(e, feed_item) {
        e.preventDefault();
        if (feed_item?.link) {
            this.props.navigate(feed_item.link);
        }
    }

    _getFeedItems() {
        const feed = this._getFeed();
        const full_page_mode = this._isFullPageMode();

        let feed_items = [];
        for (const feed_item of feed) {
            let author = '';
            if (full_page_mode && feed_item?.author?.img) {
                author = (
                    <div className="Community-Box-Feed-Author-Column">
                        <div>
                            <img src={feed_item.author.img} alt={feed_item.author.name} />
                        </div>
                        <strong>{feed_item.author.name}</strong>
                    </div>
                );
            }

            let item_img = '';
            if (feed_item?.img) {
                item_img = (
                    <img src={feed_item.img} />
                );
            }

            feed_items.push((
                <div className="Community-Box-Feed-Entry">
                    {author}
                    <div className="Community-Box-Feed-Topic-Column">
                        <div className="Community-Box-Feed-Item-Image" onClick={(e) => { this.openResource(e, feed_item); }}>
                            {item_img}
                        </div>
                        <Link to={feed_item.link}>
                            {feed_item.topic}
                        </Link>
                    </div>
                    <div className="Community-Box-Feed-Time">
                        {feed_item['+latest_comment']}
                    </div>
                    <div className="Community-Box-Feed-Stats">
                        <FontAwesomeIcon icon={icon({name: 'thumbs-up'})} /> {feed_item.likes} <FontAwesomeIcon icon={icon({name: 'thumbs-down'})} /> {feed_item.dislikes} <FontAwesomeIcon icon={icon({name: 'comment'})} /> {feed_item.comments}
                    </div>
                </div>
            ));
        }

        return (
            <>
                {feed_items}
            </>
        )
    }

    _isFullPageMode() {
        return !!this.state?.fullpage;
    }

    getCommunityFeedContent() {
        const feed = this._getFeed();
        if (!feed || this.state?.is_loading) {
            return (
                <Loading inline={true} />
            );
        }
        return (
            <div className="Community-Box-Feed">
                {this._getFeedItems()}
            </div>
        )
    }

    render() {
        const full_page_mode = this._isFullPageMode();

        let show_latest_link = null;
        if (full_page_mode && window._communityIndexPagination > 0) {
            show_latest_link = (
                <>
                    <div className="Community-Box-Feed-Show-Latest-Button-Wrapper">
                        <a href="" className="In-Place-Button" onClick={(e) => {
                            e.preventDefault();
                            window._communityIndexPagination = 0;
                            window.scrollTo({
                                top: 0,
                                behavior: 'smooth'
                            });
                            this.setState({is_loading: true});
                            window._forceUpdate().then((data) => {
                                this.setState({is_loading: false});
                                return data;
                            });
                        }}>Näytä uusimmat</a>
                    </div>
                </>
            );
        }


        return (
            <div className="Community-Box">
                {show_latest_link}
                <h2 className="Community-Box-Title">Yhteisö</h2>
                {this.getCommunityFeedContent()}
            </div>
        );
    }
}

const CommunityFeedBoxWithContext = (props) => {
    let navigate = useNavigate();
    return (<AppContext.Consumer>
        {(context) => {
            return <CommunityFeedBox {...props} navigate={navigate} context={context} />
        }}
    </AppContext.Consumer>)
};

export default CommunityFeedBoxWithContext;

