//import './Header.css';
import React from 'react';
import VisitorNavigation from './VisitorNavigation.js'
import LogoNavigation from "./LogoNavigation";
import LinksNavigation from "./LinksNavigation";
import BroadcastInfo from "./BroadcastInfo";
import {AppContext} from "../App";
//import HeaderAd from "./HeaderAd";

class Header extends React.Component {
    AdminBar = null;

    constructor(props) {
        super(props);
        this.state = {
            AdminBar: null,
        };
    }

    _getVisitor() {
        return this.props?.context?.visitor;
    }

    _getVisitorPermissions() {
        const permissions = this._getVisitor()?.account?.permissions;
        if (permissions) {
            return parseInt(permissions, 10);
        }
        return null;
    }

    render() {
        let toolbar = ( <></> );
        if (this._getVisitorPermissions() > 3) {
            let { AdminBar } = this.state;
            if (!AdminBar) {
                import('../layout/AdminBar')
                    .then(module => {
                        this.setState({
                            AdminBar: module.default,
                        });
                        window._Admin = module.AdminBar; // Expose module for static method calls
                    })
                    .catch(err => {
                        console.error("Failed to load the admin component", err);
                    });
            } else {
                toolbar = ( <AdminBar /> );
            }
        }
        return (
            <>
                {toolbar}
                <header>
                    <LogoNavigation/>
                    <BroadcastInfo/>
                    <LinksNavigation/>
                    <VisitorNavigation/>
                </header>
            </>
        )
    }
}

//export default Header;

function WithContext(props) {
    return (<AppContext.Consumer>
        {(context) => {
            return <Header {...props} context={context} />
        }}
    </AppContext.Consumer>)
}

export default WithContext;
