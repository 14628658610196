import React from 'react';
import MarkupTools from "../tools/MarkupTools";

function UserTextWithMarkup({ text }) {
    const linkifiedHtml = MarkupTools.Linkify(text);

    return (
        <div className="User-Comment-Text" dangerouslySetInnerHTML={{ __html: linkifiedHtml }} />
    );
}

export default UserTextWithMarkup;
