import './PageCommon.css';
import React from 'react';
import {useParams} from "react-router";
import {useNavigate} from "react-router-dom";
import {AppContext} from "../App";
import {NewsPage} from "./NewsPage";

class GamesPage extends NewsPage {
    constructor(props) {
        super(props);
        this.resource_type = 'games';
        this.componentDidUpdate({}, {}, {});
    }

    getResourceTypeDisplayName(form) {
        const forms = ['Pelisivu', 'Pelisivua', 'Pelit'];
        return forms[form];
    }
}

const GamesPageWithContext = (props) => {
    const { uri } = useParams();
    const navigate = useNavigate();
    return (<AppContext.Consumer>
        {(context) => {
            return <GamesPage {...props} navigate={navigate} context={context} uri={uri} />
        }}
    </AppContext.Consumer>)
};
export default GamesPageWithContext;
