import './ReservationCalendar.css';
import React, {Component} from "react";
import WebsiteApi from "../WebsiteApi";
import {icon} from "@fortawesome/fontawesome-svg-core/import.macro";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

class ReservationCalendar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            rendering: this.props?.rendering || false,
            data_loaded: false,
            loading: false,
            submitting: false,
            reservation_complete: false,
            data: null,
            from_date: new Date(),
            show_previous_month: false,
            show_next_month: true,
            reservation: {
                from_date: null,
                to_date: null,
                is_selecting: false,
                selection_over: null,
                participant_count: 6,
                check_out: "14",
                name: '',
                address: '',
                zip: '',
                city: '',
                email: '',
                phone: '',
                participants: [],
                additional_info: '',
                reserve_sauna: false,
                sauna_dates: [],
            },
        };
    }

    _getApi() {
        return new WebsiteApi();
    }

    static getInfo() {
        return {
            'name': 'Varauskalenteri',
            'description': 'Varauskalenteri&-lomake',
            parameters: {},
            init: (props) => {
                return (<ReservationCalendar {...props} />);
            },
        }
    }

    _getTimeUnixTs(date_in) {
        const date = new Date(date_in);
        return Math.floor(date.getTime() / 1000);
    }

    _getDayOfFirstDayInMonth(date_in) {
        const date = new Date(date_in);
        date.setDate(1); // Set to first day of the month
        let dayOfTheWeek = date.getDay(); // Sunday - 0, Monday - 1, ... , Saturday - 6
        if (dayOfTheWeek === 0) {
            dayOfTheWeek = 7;
        }
        return (dayOfTheWeek - 1); // Monday - 0, Tuesday 1, ... , Sunday - 6
    }

    _getCalendarTableRows(date_in) {
        const date = new Date(date_in);
        const lastDayOfTheMonth = this._getLastDayInMonth(date);
        const daysInFirstWeek = 7 - this._getDayOfFirstDayInMonth(date); // Days that fit in the first week after the first day
        const remainingDays = lastDayOfTheMonth - daysInFirstWeek; // Remaining days after filling the first week
        let totalRows = 1 + Math.ceil((remainingDays + 7) / 7); // Add the first row and then the rest + make sure there's always 7 days in the end to extend the stay, e.g. to couple days in next month
        if (totalRows > 6) {
            totalRows = 6; // Cap to 6 rows
        }
        return totalRows;
    }

    _getLastDayInMonth(date_in) {
        const date = new Date(date_in);
        let endOfMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0);
        let dayCount = endOfMonth.getDate();
        return dayCount;
    }

    _getMonthName(date_in) {
        const date = new Date(date_in);
        const monthNamesFinnish = {
            0: 'tammikuu',
            1: 'helmikuu',
            2: 'maaliskuu',
            3: 'huhtikuu',
            4: 'toukokuu',
            5: 'kesäkuu',
            6: 'heinäkuu',
            7: 'elokuu',
            8: 'syyskuu',
            9: 'lokakuu',
            10: 'marraskuu',
            11: 'joulukuu',
        };
        let year_postfix = '';
        if (date.getUTCFullYear() !== (new Date()).getUTCFullYear()) {
            year_postfix = ` ${date.getFullYear()}`
        }
        return monthNamesFinnish[date.getMonth()] + year_postfix;
    }

    _loadDataFromApi() {
        this.setState({
            data_loaded: false,
            loading: true,
            data: null,
        });
        const from_ts = this._getTimeUnixTs();
        this._getApi().getReservations(from_ts).then((data) => {
            this.setState({
                data_loaded: true,
                loading: false,
                data: data,
            });
        }); // @todo HANDLE ERRORS
    }

    componentDidMount() {
        this._loadDataFromApi();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!prevState?.rendering && this.state.rendering) {
            // Switched to rendering mode -> load data from API!
            this._loadDataFromApi();
        }
    }

    _getDateShiftedToDay(date, day) {
        const shifted_date = new Date(date);
        shifted_date.setUTCDate(day);
        shifted_date.setUTCHours(0, 0, 0, 0);
        return shifted_date;
    }

    _isDayReserved(cell_date_str, space) {
        for (let reservation of this.state.data) {
            if (reservation.space !== space) {
                continue;
            }
            if (cell_date_str >= reservation?.reservation?.from_date && cell_date_str <= reservation?.reservation?.to_date) {
                return true;
            }
        }
        return false;
    }

    _prefixLessThanTenWithZero(number) {
        if (number < 10) {
            return `0${number}`;
        }
        return number;
    }

    _getDateYearMonthDayString(date) {
        const day = this._prefixLessThanTenWithZero(date.getDate());
        return `${this._getDateYearMonthString(date)}-${day}`;
    }

    _getDateYearMonthString(date) {
        const month = this._prefixLessThanTenWithZero(date.getMonth() + 1); // ..fucking Java and zero index months
        return `${date.getFullYear()}-${month}`;
    }

    handleCalendarDayMouseOver(e) {
        e.preventDefault();
        if (!this.state.reservation.is_selecting) {
            return false; // Ignore
        }
        let reservation = this.state.reservation;
        reservation.selection_over = e.target.getAttribute('data-date');
        this.setState({
            reservation: reservation,
        });
    }

    handleCalendarDayClick(e, date_str, day_css_class, next_date_str) {
        e.preventDefault();
        if (this.state.reservation_complete) {
            // Reservation is already completed, do not allow changes
            return;
        }
        let reservation = this.state.reservation;

        if (day_css_class) {
            if (day_css_class.includes('Reserved-Day')) {
                // Selected day is not available -> ignore
                return;
            }
            if (day_css_class.includes('Blocked-Selection')) {
                if (!reservation.to_date) {
                    // Allow reselection of from date
                    if (reservation.from_date < date_str) {
                        reservation.from_date = ''; // Restore to from selection to avoid overlapping blocked selections
                    }
                } else {
                    // Selected day is not available -> ignore
                    return;
                }
            }
        }

        let current_date = this._getDateYearMonthDayString(new Date());
        if (date_str < current_date) {
            // Ignore any dates in the past
            return;
        }

        if (!reservation.selection_over) {
            // Force selection over to be set for correct indication of available dates
            reservation.selection_over = date_str;
        }

        const DetermineEndDateIfPossible = () => {
            if (next_date_str && this.reserved_dates.indexOf(next_date_str) !== -1) {
                // Next day is reserved -> automatically select as one day reservation
                reservation.is_selecting = false;
                reservation.to_date = date_str;
            } else {
                reservation.is_selecting = true;
            }
        };

        if (reservation.from_date && reservation.to_date) {
            // Both already selected -> reset selection
            reservation.from_date = date_str;
            reservation.to_date = '';
            DetermineEndDateIfPossible();
        } else if (!reservation.from_date) {
            reservation.from_date = date_str;
            DetermineEndDateIfPossible();
        } else if (date_str < reservation.from_date) {
            reservation.from_date = date_str;
            DetermineEndDateIfPossible();
        } else {
            reservation.to_date = date_str;
            reservation.is_selecting = false;
        }
        this.setState({
            reservation: reservation,
        });
        if (reservation.from_date && reservation.from_date.indexOf(this._getDateYearMonthString(this.state.from_date)) !== 0) {
            // Change to next month if selection is from next month's dates
            this.nextMonth(e);
        }
    }

    available_sauna_dates = [];
    reserved_dates = [];
    _getCalendarTableElement(date) {
        const first_day_week_pos = this._getDayOfFirstDayInMonth(date);
        const last_day = this._getLastDayInMonth(date);
        const calendar_table_rows = this._getCalendarTableRows(date);
        const is_current_month = (new Date()).getUTCMonth() === date.getUTCMonth() && (new Date()).getUTCFullYear() === date.getUTCFullYear();
        let current_day = 0;
        if (is_current_month) {
            current_day = (new Date()).getUTCDate();
        }
        let calendar = [];
        let day = 1;
        let next_month_day = 1;
        const next_month = this._getNextMonth(date);
        this.available_sauna_dates = [];
        this.reserved_dates = [];
        let first_range_blocking_date = null;
        for (let i = 0; i < calendar_table_rows; i++) {
            let days = [];
            for (let j = 0; j < 7; j++) {
                let day_label = '';
                let day_css_class = '';
                let cell_date = this._getDateShiftedToDay(date, day);
                if (i === 0 && j >= first_day_week_pos) {
                    day_label = `${day}`;
                    day_css_class = 'Current-Month-Day';
                    day++;
                } else if (i > 0 && day <= last_day) {
                    day_label = `${day}`;
                    day_css_class = 'Current-Month-Day';
                    day++;
                }

                if (is_current_month && day <= current_day) {
                    day_css_class += ` Day-Passed`;
                } else if (is_current_month && (day - 1) === current_day) {
                    day_css_class += ` Day-Is-Today`;
                }

                let month_name = null;
                if (i > 0 && day_label === '') {
                    // Next month's days
                    day_label = `${next_month_day}`;
                    day_css_class += ' Next-Month-Day';
                    month_name = this._getMonthName(next_month);
                    cell_date = this._getDateShiftedToDay(next_month, next_month_day);
                    next_month_day++;
                }

                const cell_date_str = this._getDateYearMonthDayString(cell_date);
                const next_date_str = this._getDateYearMonthDayString(new Date(cell_date.getTime() + 86400000));
                const reservation = this.state.reservation;
                if (reservation.is_selecting && cell_date_str > reservation.selection_over) {
                    if (cell_date_str !== reservation.from_date) {
                        day_css_class += ' Out-of-Active-Selection';
                    }
                } else if (reservation.from_date && cell_date_str < reservation.from_date) {
                    day_css_class += ' Out-of-Active-Selection';
                } else if (reservation.to_date && cell_date_str > reservation.to_date) {
                    day_css_class += ' Out-of-Active-Selection';
                } else if (!reservation.is_selecting && reservation.from_date && reservation.to_date && cell_date_str >= reservation.from_date && cell_date_str <= reservation.to_date) {
                    if (!this._isDayReserved(cell_date_str, 'SAUNA')) {
                        this.available_sauna_dates.push(cell_date_str);
                    }
                }

                if (this._isDayReserved(cell_date_str, 'BOOTCAMP')) {
                    day_css_class += ' Reserved-Day';
                    this.reserved_dates.push(cell_date_str);
                    if (reservation.is_selecting) {
                        day_css_class += ' Blocked-Selection';
                        if (reservation.from_date < cell_date_str) {
                            if (!first_range_blocking_date) {
                                // First reservation blocking date
                                first_range_blocking_date = cell_date_str;
                            }
                        }
                    }
                }

                if (reservation.is_selecting && first_range_blocking_date && reservation.selection_over >= first_range_blocking_date) {
                    // Disallow selection of ranges that go over other reservations
                    day_css_class += ' Blocked-Selection';
                }

                days.push((
                    <td className={day_css_class} data-day={day_label} data-month={month_name} data-date={cell_date_str} onClick={(e) => {
                        this.handleCalendarDayClick(e, cell_date_str, day_css_class, next_date_str);
                    }} onMouseOver={(e) => {
                        this.handleCalendarDayMouseOver(e);
                    }}>{day_label}</td>
                ));
            }
            calendar.push((
                <tr>
                    {days}
                </tr>
            ));
        }
        return calendar;
    }

    _getNextMonth(date_in) {
        return this._getMonthInFuture(date_in, 1);
    }

    _getMonthInFuture(date_in, add_months) {
        const new_date = new Date(date_in);
        new_date.setUTCDate(1);
        new_date.setUTCMonth(new_date.getMonth() + add_months);
        return new_date;
    }

    nextMonth(e) {
        e.preventDefault();
        const next_month = this._getNextMonth(this.state.from_date);
        const two_years_from_now = this._getMonthInFuture(new Date(), 24);
        const show_next_month = (this._getDateYearMonthDayString(two_years_from_now) >= this._getDateYearMonthDayString(next_month));
        this.setState({
            from_date: next_month,
            show_previous_month: true,
            show_next_month: show_next_month,
        });
    }

    previousMonth(e) {
        e.preventDefault();
        const new_date = new Date(this.state.from_date);
        new_date.setUTCDate(1);
        new_date.setUTCMonth(new_date.getMonth() - 1);
        const two_months_back = new Date(new_date);
        two_months_back.setMonth(new_date.getMonth() - 1);
        const is_same_year = two_months_back.getUTCFullYear() === (new Date()).getUTCFullYear();
        this.setState({
            from_date: new_date,
            show_previous_month: (!is_same_year || two_months_back.getUTCMonth() >= (new Date()).getUTCMonth()),
            show_next_month: true,
        });
    }

    render() {
        if (!this.state.rendering) {
            return(
                <div className="Reservation-Calendar" data-component="DC:ReservationCalendar" contentEditable={false}>[Varauskalenteri]</div>
            );
        }
        // Will be dynamically rendered into the above div, which is transformed into:
        // <div class="Reservation-Calendar" contenteditable="false"></div>

        if (this.state.loading) {
            return(
                <div>Latailee..</div>
            );
        }
        if (this.state?.data === null) {
            console.error('ReservationCalendar: data IS NULL, but NOT loading!');
            return (<>Lataa..?</>);
        }
        const date = this.state.from_date;
        const calendar = this._getCalendarTableElement(date);

        const reservation = this.state.reservation;
        let container_css_class = 'Reservation-Calendar-Container';
        if (this.state.submitting) {
            container_css_class += ' Reservation-Submitting';
        }
        return(
            <div className={container_css_class}>
                <table className="Reservation-Calendar-Month-View">
                    <colgroup>
                        <col span="1" />
                        <col span="1" />
                        <col span="1" />
                        <col span="1" />
                        <col span="1" />
                        <col span="1" />
                        <col span="1" />
                    </colgroup>
                    <thead>
                    <tr>
                        {(this.state.show_previous_month ? (
                            <th className="Month-Header Navigation-Action" onClick={(e) => {
                                this.previousMonth(e);
                            }}>
                                <FontAwesomeIcon icon={icon({name: 'chevron-left'})}/>
                            </th>
                        ) : (<th className="Month-Header"></th>)
                        )}
                        <th className="Month-Header" colSpan="5">{this._getMonthName(date)}</th>
                        {(this.state.show_next_month ? (
                                <th className="Month-Header Navigation-Action" onClick={(e) => {
                                    this.nextMonth(e);
                                }}>
                                    <FontAwesomeIcon icon={icon({name: 'chevron-right'})}/>
                                </th>
                            ) : (<th className="Month-Header"></th>)
                        )}
                    </tr>
                        <tr>
                            <th>M</th>
                            <th>T</th>
                            <th>K</th>
                            <th>T</th>
                            <th>P</th>
                            <th>L</th>
                            <th>S</th>
                        </tr>
                    </thead>
                    <tbody className={(reservation.is_selecting ? 'Is-Selecting' : '')}>
                        {calendar}
                    </tbody>
                </table>

                <div className="Flex-Row" style={{paddingBottom: '20px'}}>
                    <div className="Flex-Column">
                        <label>Aloituspäivä:
                            <input type="date" value={this.state.reservation.from_date} onChange={(e) => {
                                this.handleCalendarDayClick(e, e.target.value, '');
                            }} />
                        </label>
                    </div>
                    <div className="Flex-Column">
                        <label>Lopetuspäivä:
                            {this.state.reservation.from_date ? (
                                <input type="date" value={this.state.reservation.to_date} onChange={(e) => {
                                    this.handleCalendarDayClick(e, e.target.value, '');
                                }} />
                            ) : (
                                <><br />&mdash;</>
                            )}
                        </label>
                    </div>
                </div>
                <div className="Reservation-Information"
                     style={{
                         filter: (this.isReservationAllowed() ? 'none' : 'blur(100px) grayscale(1)'),
                         marginTop: (this.isReservationAllowed() ? '0' : '-455px'),
                         opacity: (this.isReservationAllowed() ? '1' : '0'),
                         zIndex: (this.isReservationAllowed() ? '99' : '-1')
                     }}>
                    <label>
                        {/** https://codepen.io/vsync/pen/mdEJMLv?editors=1100 */}
                        Henkilömäärä:
                        <input type="range" min="1" max="10" value={this.state.reservation.participant_count}
                               disabled={this.isReservationFormDisabled()} className="Reservation-Slider"
                               onChange={(e) => {
                                   this.handleReservationFieldChange(e, 'participant_count');
                               }}/>
                        <small>
                            {(this.state.reservation.participant_count <= 8 ? this.state.reservation.participant_count : 8)} henkilöä
                            {(this.state.reservation.participant_count > 8 ? ` + ${(this.state.reservation.participant_count - 8)} lisähenkilöä (+${(this.state.reservation.participant_count - 8) * 50}€), yht. ${this.state.reservation.participant_count} henkilöä` : '')}
                        </small>
                    </label>
                    <div><em>Varaajan tiedot:</em></div>
                    <div className="Flex-Row">
                        <div className="Flex-Column Align-Right">
                            <label htmlFor="reservation_name">Nimi:</label>
                        </div>
                        <div className="Flex-Column">
                            <input type="text" required={true} disabled={this.isReservationFormDisabled()} name="name"
                                   id="reservation_name" value={this.state.reservation.name}
                                   onChange={(e) => {
                                       this.handleReservationFieldChange(e, 'name');
                                   }} placeholder="Nimi"/>
                        </div>
                    </div>
                    <div className="Flex-Row">
                        <div className="Flex-Column Align-Right">
                            <label htmlFor="reservation_street_address">Osoite:</label>
                        </div>
                        <div className="Flex-Column">
                            <input type="text" required={true} disabled={this.isReservationFormDisabled()}
                                   name="street_address" id="reservation_street_address"
                                   value={this.state.reservation.address}
                                   onChange={(e) => {
                                       this.handleReservationFieldChange(e, 'address');
                                   }} placeholder="Osoite"/>
                        </div>
                    </div>
                    <div className="Flex-Row">
                        <div className="Flex-Column Align-Right">
                            <label htmlFor="reservation_zip">Postinumero:</label>
                        </div>
                        <div className="Flex-Column">
                            <input type="number" required={true} disabled={this.isReservationFormDisabled()} name="zip"
                                   id="reservation_zip" placeholder="Postinumero" maxLength="5" min="1" max="99999"
                                   value={this.state.reservation.zip}
                                   onChange={(e) => {
                                       this.handleReservationFieldChange(e, 'zip');
                                   }}/>
                        </div>
                    </div>
                    <div className="Flex-Row">
                        <div className="Flex-Column Align-Right">
                            <label htmlFor="reservation_zip">Kaupunki:</label>
                        </div>
                        <div className="Flex-Column">
                            <input type="text" required={true} disabled={this.isReservationFormDisabled()} name="city"
                                   list="finnish_cities" placeholder="Kaupunki" value={this.state.reservation.city}
                                   onChange={(e) => {
                                       this.handleReservationFieldChange(e, 'city');
                                   }}/>
                        </div>
                        <datalist id="finnish_cities">
                            {this._getFinnishCities().map((city, index) => (
                                <option key={index} value={city}/>
                            ))}
                        </datalist>
                    </div>
                    <div className="Flex-Row">
                        <div className="Flex-Column Align-Right">
                            <label htmlFor="reservation_email">Sähköposti:</label>
                        </div>
                        <div className="Flex-Column">
                            <input type="email" required={true} disabled={this.isReservationFormDisabled()} name="email"
                                   id="reservation_email" placeholder="Sähköposti" value={this.state.reservation.email}
                                   onChange={(e) => {
                                       this.handleReservationFieldChange(e, 'email');
                                   }}/>
                        </div>
                    </div>
                    <div className="Flex-Row">
                        <div className="Flex-Column Align-Right">
                            <label htmlFor="reservation_phone">Puhelin:</label>
                        </div>
                        <div className="Flex-Column">
                            <input type="tel" required={true} disabled={this.isReservationFormDisabled()} name="phone"
                                   id="reservation_phone" placeholder="Puhelin" value={this.state.reservation.phone}
                                   onChange={(e) => {
                                       this.handleReservationFieldChange(e, 'phone');
                                   }}/>
                        </div>
                    </div>
                    <div className="Flex-Row">
                        <div className="Flex-Column Align-Right">
                            <label>Osallistujat:</label>
                        </div>
                        <div className="Flex-Column">
                            {this._getPartipantNameInputs()}
                        </div>
                    </div>
                    <div className="Flex-Row">
                        <div className="Flex-Column">
                            <label htmlFor="reservation_extra_info">
                                Lisätietoja:
                            </label>
                            <textarea rows="5" id="reservation_extra_info" disabled={this.isReservationFormDisabled()}
                                      placeholder="Esim. onko kyseessä kilpapelitiimi, kaveriporukka.."
                                      value={this.state.reservation.additional_info}
                                      onChange={(e) => {
                                          this.handleReservationFieldChange(e, 'additional_info');
                                      }}></textarea>
                        </div>
                    </div>
                    <div className="Flex-Row">
                        <div className="Flex-Column Align-Right">
                            <label htmlFor="reservation_name">Check-out:</label>
                        </div>
                        <div className="Flex-Column">
                            <select required={true} disabled={this.isReservationFormDisabled()} name="check_out"
                                id="check_out" value={this.state.reservation.check_out} onChange={(e) => {
                                    this.handleReservationFieldChange(e, 'check_out');
                                }}>
                                <option value="14">14:00</option>
                                <option value="18">18:00 (+50€)</option>
                            </select>
                        </div>
                    </div>
                    <div>
                        <label>
                            <input type="checkbox" disabled={this.isSaunaReservationDisabled()} name="reserve_sauna"
                                   value={this.state.reservation.reserve_sauna}
                                   onChange={(e) => {
                                       this.handleReservationFieldChange(e, 'reserve_sauna', 'checked');
                                   }}/> Varaa <a href="" onClick={(e) => {
                            e.preventDefault();
                            alert('@todo: AVAA SAUNA-SIVU UUTEEN IKKUNAAN');
                        }}>saunatila</a> <small>(+250€/vrk)</small>
                        </label>
                        <div className="Flex-Row">
                            <div className="Flex-Column"></div>
                            <div className="Flex-Column">
                                {this._getAvailableSaunaDates()}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="Reservation-Actions-Wrapper"
                     style={{display: (this.state.reservation_complete ? 'none' : '')}}>
                    <button className="Full-Width-Button" disabled={this.isReservationFormDisabled()} onClick={(e) => {
                        this.submitReservation(e);
                    }}>Varaa
                    </button>
                    <small className="Reservation-Terms-Info">
                        Tekemällä varauksen hyväksyt <a href="" onClick={(e) => {
                        e.preventDefault();
                        alert('@todo: VARAUSEHDOT?');
                    }}>varausehdot</a>
                    </small>
                </div>
                <div style={{display: (this.state.reservation_complete ? '' : 'none')}}>
                    <strong>Hermeettistä!</strong>
                    <p>Varaus on nyt lähetetty, merkkaa se kalenteriisi ja saat pian lisätietoja meiltä varaukseesi liittyen</p>
                </div>
            </div>
        );
    }

    submitReservation(e) {
        e.preventDefault();

        this.setState({
            submitting: true,
        });
        this._getApi().submitReservation(this.state.reservation).then((data) => {
            console.dir(data); // DEBUG
            if (data?.RESERVATIONS) {
                this.setState({
                    data: data.RESERVATIONS,
                    reservation_complete: true,
                });
            }
        }).catch((e) => {
            console.dir(e);
            let msg = 'Tapahtui virhe! Yritä uudestaan!';
            if (e?.response?.data?.message) {
                msg = `Virhe: ${e.response.data.message}`;
            }
            alert(msg); // @todo: NICER ERROR TOAST
        }).finally(() => {
            this.setState({
                submitting: false,
            });
        }); // @todo: HANDLE ERRORS
    }

    _getAvailableSaunaDates() {
        if (!this.isReservationAllowed()) {
            return null;
        }
        if (this.state.reservation_complete) {
            // Hide sauna selections when submitted
            return null;
        }
        let available_dates = [];
        if (!this.state.reservation.reserve_sauna) {
            // Do not show dates for sauna, if sauna not added to the reservation
            return null;
        }
        for (let date of this.available_sauna_dates) {
            available_dates.push((
                <>
                    <label>
                        <input type="checkbox" name={'sauna_' + date} disabled={this.isReservationFormDisabled()}  checked={this.state.reservation.sauna_dates.indexOf(date) !== -1}
                               onChange={(e) => {
                                   let sauna_dates = this.state.reservation.sauna_dates;
                                   if (e.target.checked) {
                                       sauna_dates.push(date);
                                   } else {
                                       sauna_dates = sauna_dates.filter(sauna_date => sauna_date !== date);
                                   }
                                   this.handleReservationFieldChange({target: {value: sauna_dates}}, 'sauna_dates');
                               }} /> {date}
                    </label>
                </>
            ));
        }
        return available_dates;
    }

    handleReservationFieldChange(e, field, target_property) {
        const reservation = this.state.reservation;
        let target_value = e.target.value;
        if (target_property) {
            target_value = e.target[target_property];
        }
        reservation[field] = target_value;
        this.setState({
            reservation
        });
    }

    _getPartipantNameInputs() {
        if (!this.isReservationAllowed()) {
            return null;
        }
        let name_fields = [];
        name_fields.push((
            <input type="text" name="name" value={this.state.reservation.name} disabled={true} placeholder="1. osallistujan nimi"/>
        )); // Own name
        for (let i = 1; i < this.state.reservation.participant_count; ++i) {
            name_fields.push((
                <input type="text" disabled={this.isReservationFormDisabled()} name={'participant_' + (i + 1)} required={true} placeholder={(i + 1) + '. osallistujan nimi'}  value={this.state.reservation.participants[i - 1]}
                       onChange={(e) => {
                           let participants = this.state.reservation.participants;
                           participants[i - 1] = e.target.value;
                           this.handleReservationFieldChange({target: {value: participants}}, 'participants');
                       }}/>
            ));
        }
        // @todo: ADD FIELDS ONE BY ONE ONCE WRITING MORE..
        return name_fields;
    }

    isSaunaReservationDisabled() {
        if (this.isReservationFormDisabled()) {
            return true;
        }
        if (!this.available_sauna_dates || this.available_sauna_dates.length === 0) {
            return true;
        }
        return false;
    }

    isReservationFormDisabled() {
        return !this.isReservationAllowed() || this.state.submitting || this.state.reservation_complete;
    }

    isReservationAllowed() {
        return this.state.reservation.from_date && this.state.reservation.to_date;
    }

    _getFinnishCities() {
        return ["Helsinki","Espoo","Tampere","Vantaa","Oulu","Turku","Jyväskylä","Lahti","Kuopio","Pori","Lappeenranta","Vaasa","Kotka","Joensuu","Hämeenlinna","Porvoo","Mikkeli","Hyvinkää","Järvenpää","Rauma","Lohja","Kouvola","Kokkola","Rovaniemi","Seinäjoki","Salo","Kajaani","Kerava","Kirkkonummi","Tuusula","Nokia","Ylöjärvi","Kaarina","Riihimäki","Kemi","Savonlinna","Iisalmi","Raahe","Tornio","Raasepori","Varkaus","Äänekoski","Imatra","Nurmijärvi","Jämsä","Lempäälä","Sastamala","Kerava","Valkeakoski","Kuusamo","Pietarsaari","Naantali","Lieto","Hamina","Mäntsälä","Forssa","Heinola","Hollola","Siilinjärvi","Hämeenlinna","Pirkkala","Ylivieska","Ulvila","Uusikaupunki","Kauhajoki","Pietarsaari","Kuusankoski","Jakobstad","Lappeenranta","Jämsä","Uusikaarlepyy","Kankaanpää","Haukipudas","Orimattila","Loviisa","Loimaa","Kitee","Kangasala","Vihti","Nurmo","Lapua","Lieksa","Pieksämäki","Kuhmo","Nivala","Kiuruvesi","Hanko","Alavus","Uusikaupunki","Paimio","Parkano","Outokumpu","Haapavesi","Akaa","Lapinlahti","Savonlinna","Suonenjoki","Kuusankoski","Saarijärvi","Muhos"];
    }
}

export default ReservationCalendar;
