import './LogoNavigation.css';
import React from 'react';
import { Link } from "react-router-dom";

class LogoNavigation extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <Link to="/">
                <div className="Logo-Navigation"></div>
            </Link>
        );
    }
}

export default LogoNavigation;
