import './AdBox.css';
import React from 'react';
import { AppContext } from '../App.js'

class AdBox extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        if (!this.props?.position || !this.props?.context?.components?.reklaami) {
            return null;
        }
        const ad = this.props.context.components.reklaami[this.props.position];
        if (!ad) {
            return null;
        }

        if (ad.html) {
            return (
                <div className="Ad-Box" dangerouslySetInnerHTML={{__html: ad.html}} />
            );
        }

        let ad_content = null;
        if (ad.img_url) {
            ad_content = (
                <img src={ad.img_url} />
            );
        }
        if (ad.img_link) {
            ad_content = (
                <a href={ad.img_link} target="_blank">
                    {ad_content}
                </a>
            );
        }

        return (
            <div className="Ad-Box">
                {ad_content}
            </div>
        );
    }
}

const AdBoxWithContext = (props) => {
    return (<AppContext.Consumer>
        {(context) => {
            return <AdBox {...props} context={context} />
        }}
    </AppContext.Consumer>)
};
export default AdBoxWithContext;
